import { AfterViewInit, Component , ElementRef, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { ScheduleService } from 'src/app/private/services/schedule.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { NgbDateStruct, NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MatMenuTrigger } from '@angular/material/menu';
@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleComponent implements AfterViewInit {
  loading:boolean = false;
  btnLoading:boolean = false;
  buttonTitle:any = "submit";
  dialogBox:boolean = false;
  AppointmentConfig:any;
  tabWindow = 0;
  selectedngmodal:any;
  selectedDoctor:any
  departmentListDp:any = [];
  selectedDoctorName:any;
  doctorList:any = [];
  scheduleList:any = [];
  DoctorDpList:any = [];
  deptid:any;
  deptName:any;
  bookingTypeTitle:any = "";
  selectedDoctorObject:any;
  appointmentBookingTypeobject:any = ["clinic","online","home_visit"];
  @ViewChild('stripScroll', { read: ElementRef })
  public stripScroll!: ElementRef<any>;
  @ViewChild('menuTrigger') trigger:any;
  @ViewChild('menuTriggerType') menuTriggerType:any;
  deleteItem : any  = {item:"",index:""};
  deleteWindow:boolean = false;
  datesSelected:any[]=[]; 
  vacationDate:any;
  @ViewChild('scrollContainer', { static: true })
  scrollContainer!: ElementRef;

  

  constructor(private modalService: NgbModal, private toast:ToastrService,private offcanvasService: NgbOffcanvas,private api:ScheduleService,private localStorage:LocalStorageService)
  {

  }

  scrollToBottom(): void {
    if (this.scrollContainer) {
      const container = this.scrollContainer.nativeElement;
      container.scrollTop = container.scrollHeight;
    }
}
  addVacations(value:any)
  {
    //alert(value.day+'-'+value.month+'-'+value.year)
    let date =  moment(value.day+'-'+value.month+'-'+value.year,'DD/MM/YYYY').format("YYYY-MM-DD");
    if(!this.datesSelected.includes(date)){
      this.datesSelected.push(date);
      this.scrollToBottom()
    }

  }
  removeDates(i:any)
  {
    this.datesSelected =  this.datesSelected.filter((e)=> i!=e);
    console.log("dates : ",this.datesSelected);

  }
  openSeeting(content: TemplateRef<any>) {
		this.offcanvasService.open(content, { position: 'end' });
	}
  ngAfterViewInit()
  {
   this.getList();
  }
  closeTrigger()
  {
    this.trigger.closeMenu();
    this.menuTriggerType.closeMenu();
  }  

  switchDoctor(i:any,item:any)
  {
    //this.appointmentBookingTypeobject = [];
    //this.appointmentBookingTypeobject = Object.keys(this.selectedngmodal) || this.appointmentBookingTypeobject;
    this.selectedDoctor = i;
    this.selectedngmodal = item.schedule;
 //   console.log(Object.keys(item.schedule))
    this.appointmentBookingTypeobject = Object.keys(item.schedule)
    this.datesSelected = this.selectedngmodal[this.appointmentBookingTypeobject[this.tabWindow]]['vacations'] || []
 

  }
  getCurrentType(item:any)
  {
    return Object.keys(item)
  }
  openDelete(content:any,item:any,i:any)
  {
    this.deleteItem.item = item;
    this.deleteItem.i = i;
    this.deleteWindow = true
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {  
      if (result === 'yes') {  
        this.deleteSchedule(item.schedule_id)
      }  
    }, (reason) => {  

    });  
  }
  deleteSchedule(id:any)
  {
    this.scheduleList.splice(this.deleteItem.i,1)
    this.api.deleteSchedule(id).subscribe({
      next:(s)=>{
        this.toast.success("Record Deleted Successfully");
        this.getList();
      
      }
    })  
  }
  public arrowscrollLeft(): void {
    this.stripScroll.nativeElement.scrollTo({ left: (this.stripScroll.nativeElement.scrollLeft - 250), behavior: 'smooth' });
  }
  public arrowscrollRight(): void {
    this.stripScroll.nativeElement.scrollTo({ left: (this.stripScroll.nativeElement.scrollLeft + 250), behavior: 'smooth' });
  }

  switchTab(n: any) {
    this.tabWindow = n;
    this.datesSelected = this.selectedngmodal[this.appointmentBookingTypeobject[this.tabWindow]]['vacations'] || []

  }
  removeSpCh(data:any)
  {
    return  data.replaceAll("_"," ");
  }
  loadDropdowns()
  {
    let param = {
      where:{
        hospital_id:this.localStorage.getHospitalId(),
        // center_id:this.localStorage.getCenterId(),
        isPublic:true,
      }

    }
   this.api.getDepartments(param).subscribe({
    next:(response)=>{
     // console.log(response)
      this.departmentListDp = response
    },
    error:(e)=>{

    }
   })
  }
  loadDoctorsByDepartments(event:any)
  {
    let selectElementText = event.target['options']
    [event.target['options'].selectedIndex].text;
    this.deptid = event.target.value;
    this.deptName = selectElementText;
    let param = {
      hospital_id:this.localStorage.getHospitalId(),
      center_id:this.localStorage.getCenterId(),
      dept_id:event.target.value,
 
    }
    if(this.localStorage.getIgnoreCenterCheck()=="true"){
      delete param.center_id;
    }
    this.api.getUsersByDepartments(param).subscribe({
      next:(response)=>{
       // console.log(response)
        this.DoctorDpList = response
      },
      error:(e)=>{
  
      }
     })
  }
  onSelectDoctor(item : any)
  {
    let selectElementText = item.target['options']
    [item.target['options'].selectedIndex].text;
    let value = item.target.value;
    this.selectedDoctorName = selectElementText;
    let row = this.DoctorDpList.find((item:any)=> item.user_id == value );
   // console.log(value)
    this.selectedDoctorObject = {prefix:row.prefix,name:selectElementText,id:value};
  
   //console.log( this.selectedDoctorObject)
  }

  addSchedule()
  {

    this.selectedDoctor = this.selectedDoctorObject.id;
    this.AppointmentConfig = {
      hospital_id:this.localStorage.getHospitalId(),
      center_id:this.localStorage.getCenterId(),
      name:this.selectedDoctorObject.name,
      user_id:this.selectedDoctorObject.id,
      deptname:this.deptName,
      dept_id:this.deptid,
      schedule : {
        "clinic": {
          "enablePayment": "",
          "advance_appointment_days":"1",
          "isTrue": false,
          "vacations":[],
          "charges":[],
          "fees": "",
          "working_days": {
           "Sunday": {
             "slots": {},
            "isTrue": false,
            "config": [{
             "from":"",
             "to":"",
             "num_of_appointment": "",
             "slot_duration": "0",
            }],
           },
           "Monday": {
             "isTrue": false,
             "slots": {},
             "config": [{
              "from":"",
              "to":"",
              "num_of_appointment": "",
              "slot_duration": "0",
             }],
            },
            "Tuesday": {
             "isTrue": false,
             "slots": {},
             "config": [{
              "from":"",
              "to":"",
              "num_of_appointment": "",
              "slot_duration": "0",
             }],
            },
            "Wednesday": {
             "isTrue": false,
             "slots": {},
             "config": [{
              "from":"",
              "to":"",
              "num_of_appointment": "",
              "slot_duration": "0",
             }],
            },
            "Thursday": {
             "isTrue": false,
             "slots": {},
             "config": [{
              "from":"",
              "to":"",
              "num_of_appointment": "",
              "slot_duration": "0",
             }],
            },
            "Friday": {
             "isTrue": false,
             "slots": {},
             "config": [{
              "from":"",
              "to":"",
              "num_of_appointment": "",
              "slot_duration": "0",
             }],
            },
            "Saturday": {
             "isTrue": false,
             "slots": {},
             "config": [{
              "from":"",
              "to":"",
              "num_of_appointment": "",
              "slot_duration": "0",
             }],
            },
           
          }
         },
         "home_visit": {
           "enablePayment": "",
           "advance_appointment_days":"1",
           "isTrue": false,
           "vacations":[],
           "charges":[],
           "fees": "",
           "working_days": {
            "Sunday": {
             "slots": {},
             "isTrue": false,
             "config": [{
              "from":"",
              "to":"",
              "num_of_appointment": "",
              "slot_duration": "0",
             }],
            },
            "Monday": {
             "isTrue": false,
             "slots": {},
             "config": [{
              "from":"",
              "to":"",
              "num_of_appointment": "",
              "slot_duration": "0",
             }],
            },
            "Tuesday": {
             "isTrue": false,
             "slots": {},
             "config": [{
              "from":"",
              "to":"",
              "num_of_appointment": "",
              "slot_duration": "0",
             }],
            },
            "Wednesday": {
             "isTrue": false,
             "slots": {},
             "config": [{
              "from":"",
              "to":"",
              "num_of_appointment": "",
              "slot_duration": "0",
             }],
            },
            "Thursday": {
             "isTrue": false,
             "slots": {},
             "config": [{
              "from":"",
              "to":"",
              "num_of_appointment": "",
              "slot_duration": "0",
             }],
            },
            "Friday": {
             "isTrue": false,
             "slots": {},
             "config": [{
              "from":"",
              "to":"",
              "num_of_appointment": "",
              "slot_duration": "0",
             }],
            },
            "Saturday": {
             "isTrue": false,
             "slots": {},
             "config": [{
              "from":"",
              "to":"",
              "num_of_appointment": "",
              "slot_duration": "0",
             }],
            },
            
           }
          },
          "online": {
           "enablePayment": "",
           "advance_appointment_days":"1",
           "isTrue": false,
           "vacations":[],
           "charges":[],
           "fees": "",
           "working_days": {
            "Sunday": {
             "isTrue": false,
             "slots": {},
             "config": [{
              "from":"",
              "to":"",
              "num_of_appointment": "",
              "slot_duration": "0",
             }],
            },
            "Monday": {
             "isTrue": false,
             "slots": {},
             "config": [{
              "from":"",
              "to":"",
              "num_of_appointment": "",
              "slot_duration": "0",
             }],
            },
            "Tuesday": {
             "isTrue": false,
             "slots": {},
             "config": [{
              "from":"",
              "to":"",
              "num_of_appointment": "",
              "slot_duration": "0",
             }],
            },
            "Wednesday": {
             "isTrue": false,
             "slots": {},
             "config": [{
              "from":"",
              "to":"",
              "num_of_appointment": "",
              "slot_duration": "0",
             }],
            },
            "Thursday": {
             "isTrue": false,
             "slots": {},
             "config": [{
              "from":"",
              "to":"",
              "num_of_appointment": "",
              "slot_duration": "0",
             }],
            },
            "Friday": {
             "isTrue": false,
             "slots": {},
             "config": [{
              "from":"",
              "to":"",
              "num_of_appointment": "",
              "slot_duration": "0",
             }],
            },
            "Saturday": {
             "isTrue": false,
             "slots": {},
             "config": [{
              "from":"",
              "to":"",
              "num_of_appointment": "",
              "slot_duration": "0",
             }],
            },
          }
        }
      }
    }
    this.selectedngmodal = this.AppointmentConfig.schedule;
    //console.log(this.AppointmentConfig);
    let exist = this.scheduleList.find((item:any)=> item.user_id == this.AppointmentConfig.user_id)
    if(!exist)
    {
      this.scheduleList.push(this.AppointmentConfig);
      this. closeTrigger();
    }
    else
    {
      this.toast.error("This User is already added")
      this. closeTrigger();
    }

  }
  updateStatus(event:any,item:any)
  {
   // console.log(event.checked)
    let param = {
       status:event.checked,
       schedule_id:item.schedule_id,
       hospital_id:item.hospital_id,
    }
    //console.log(param)
    this.api.updateSchedule(param).subscribe({
      next:(res) =>{
        console.log(res)
        if(res)
        {
          if(event.checked)
          {
            this.toast.success('Status change to online')
          }
          else
          { 
            this.toast.success('Status change to offline')
          }
       
        }
    

      },
      error:(e)=>{
        this.toast.error(e)
        this.btnLoading = false;
        this.buttonTitle = "submit"
      }
    })
  }

  timeValidationfrom(n:any,event:any,modal:any)
  {
    let start_time = modal.config[n].from
    let end_time = modal.config[n].to
   
    if(n > 0)
    {
      let last_tme = modal.config[n - 1].to;
      console.log(last_tme+"-------"+event.target.value )
    
        if(event.target.value  < last_tme)
        {
          // this._snackBar.open('Start time should be greater thant last end time', 'Close', {
          //   duration:2000,
          //   panelClass: ['danger'],
          //   horizontalPosition: this.horizontalPosition,
          //   verticalPosition: this.verticalPosition,
          // });
          modal.config[n].from = "";
        }
 
    
  }

  }

  timeValidationto(n:any,event:any,modal:any)
  {
    let start_time = modal.config[n].from
    let end_time = modal.config[n].to
   
    if(modal.isTrue==false)
    {
      this.toast.error('Please select day')
      modal.config[n].to = ""
    }
    else
    {
      if(start_time=="")
      {
        this.toast.error('Please enter start time')
        modal.config[n].to = ""
      }
      else
      {
      
        if(end_time < start_time)
        {
          this.toast.error('End time should greater by start time')
          modal.config[n].to = ""
        }
      }
    }

  }
  getTotalAppointments(n:any,event:any,modal:any)
  {}
  addMore(item:any,index:any)
  {
    
    item.push({
      "from":"",
      "to":"",
      "num_of_appointment": "",
      "slot_duration": "0",
     })
  }
  deleteRow(item:any,index:any)
  {
    if(index!=0)
    {
      item.splice(index)
    }
    
  }
  show_alert(msg:any)
  {
    this.toast.error(msg)
    // this._snackBar.open(msg, 'Close', {
    //   duration:2000,
    //   panelClass: ['danger'],
    //   horizontalPosition: this.horizontalPosition,
    //   verticalPosition: this.verticalPosition,
    // });
  }

  getList()
  {
    let param = {
      hospital_id:this.localStorage.getHospitalId(),
      center_id:this.localStorage.getCenterId(),
    }
    if(this.localStorage.getIgnoreCenterCheck()=="true"){
      delete param.center_id;
    }
    this.api.getAllSchedule(param).subscribe({
      next:(res) =>{
        //console.log(res)
        if(res.length > 0)
        {
          this.scheduleList = res || [];
          //console.log(this.scheduleList)
          this.selectedngmodal = this.scheduleList[0]['schedule'];
          this.selectedDoctorName =   this.scheduleList[0].name;
          this.selectedDoctor = this.scheduleList[0].user_id;
          this.appointmentBookingTypeobject = Object.keys(this.selectedngmodal)
          this.datesSelected = this.selectedngmodal[this.appointmentBookingTypeobject[this.tabWindow]]['vacations']
        }
      
       
      },
      error:(e)=>{}
    })
  }
  createBookingType()
  {
    if(this.bookingTypeTitle)
    {
    
  
        var object:any = {};
        let typeName = this.bookingTypeTitle.toLowerCase()
        typeName = typeName.replaceAll(" ","_");
  
      
          object[typeName] = {
            "enablePayment": "",
            "advance_appointment_days":"1",
            "isTrue": false,
            "vacations":[],
            "charges":[],
            "price": "",
            "working_days": {
             "Sunday": {
               "slots": {},
              "isTrue": false,
              "config": [{
               "from":"",
               "to":"",
               "num_of_appointment": "",
               "slot_duration": "0",
              }],
             },
             "Monday": {
               "isTrue": false,
               "slots": {},
               "config": [{
                "from":"",
                "to":"",
                "num_of_appointment": "",
                "slot_duration": "0",
               }],
              },
              "Tuesday": {
               "isTrue": false,
               "slots": {},
               "config": [{
                "from":"",
                "to":"",
                "num_of_appointment": "",
                "slot_duration": "0",
               }],
              },
              "Wednesday": {
               "isTrue": false,
               "slots": {},
               "config": [{
                "from":"",
                "to":"",
                "num_of_appointment": "",
                "slot_duration": "0",
               }],
              },
              "Thursday": {
               "isTrue": false,
               "slots": {},
               "config": [{
                "from":"",
                "to":"",
                "num_of_appointment": "",
                "slot_duration": "0",
               }],
              },
              "Friday": {
               "isTrue": false,
               "slots": {},
               "config": [{
                "from":"",
                "to":"",
                "num_of_appointment": "",
                "slot_duration": "0",
               }],
              },
              "Saturday": {
               "isTrue": false,
               "slots": {},
               "config": [{
                "from":"",
                "to":"",
                "num_of_appointment": "",
                "slot_duration": "0",
               }],
              },
             
            }
           };
           this.appointmentBookingTypeobject.push(typeName);
           this.selectedngmodal = {...this.selectedngmodal,...object}

           this.scheduleList.forEach((element:any, index:Number) => {
            if(element.user_id === this.selectedDoctor) {
              element['schedule'] = {...this.selectedngmodal,...object};
            }
        });
           this.arrowscrollRight();
           this.bookingTypeTitle = "";
           this. closeTrigger();
    // console.log( this.selectedngmodal)
    }
    else
    {
      this.toast.error('Enter Booking Type Title')
    }
  
  }
  removeObjectType(item:any,i:Number)
  {
    this.appointmentBookingTypeobject.splice(i,1);
    delete this.selectedngmodal[item]; 
    
  }
  save()
  {

    this.getTimeSlots();

   // console.log(this.selectedngmodal)
    delete this.scheduleList['deptname'];
    if(this.datesSelected && this.datesSelected.length > 0){
      this.selectedngmodal[this.appointmentBookingTypeobject[this.tabWindow]]['vacations'] = this.datesSelected
    }
    if(this.datesSelected.length == 0) {
      this.selectedngmodal[this.appointmentBookingTypeobject[this.tabWindow]]['vacations'] = [];
    }
   // console.log(this.selectedngmodal[this.appointmentBookingTypeobject[this.tabWindow]])

    if(this.scheduleList)
    {
      this.btnLoading = true;
      this.buttonTitle = "saving..."
      //console.log(this.scheduleList)
      this.api.bulkSchedule(this.scheduleList).subscribe({
        next:(res) =>{
          console.log(res)
          this.toast.success('Schedule Updated..!!!')
          this.btnLoading = false;
          this.buttonTitle = "submit"
         // this.scheduleList = [];
         this.offcanvasService.dismiss();
         this.getList();
        },
        error:(e)=>{
          this.toast.error(e)
          this.btnLoading = false;
          this.buttonTitle = "submit"
        }
      })
    }
    else
    {
      this.btnLoading = false;
      this.buttonTitle = "submit"
      this.toast.error('No Schedule for update')
    }
    

  //  console.log(this.scheduleList)
  }

  getTimeSlots(){


    let schedule = this.scheduleList;

      for(let x in schedule){
        for(let y in schedule[x]['schedule']){
         // console.log("con1123 ", y);
          if(schedule[x]['schedule'][y].isTrue === true) {
            //console.log("con2 ", y);
            for(let z in schedule[x]['schedule'][y].working_days){
              if(schedule[x]['schedule'][y].working_days[z].isTrue === true) {
               // console.log("con3 ", z);
                var timeStops = [];
                for(let i = 0; i<schedule[x]['schedule'][y].working_days[z].config.length; i++) {    
                 // console.log(this.AppointmentConfig.doctors[x]['schedule'][y].working_days[z].config[i].from)
                  var startTime = moment(schedule[x]['schedule'][y].working_days[z].config[i].from , 'HH:mm');
                  var endTime = moment(schedule[x]['schedule'][y].working_days[z].config[i].to , 'HH:mm');
                  var duration = schedule[x]['schedule'][y].working_days[z].config[i].slot_duration;
                 // console.log(moment(startTime).format('HH:mm') +""+moment(endTime).format('HH:mm'));
                    if(startTime != null){                  
                        while(startTime < endTime){
                        timeStops.push(moment(startTime).format('HH:mm'));
                        //console.log("duration"+duration)
                        startTime.add(duration, 'minutes');
                      }
                    }
                   
                }
                const jsonString = JSON.stringify(Object.assign({}, timeStops));
             
                schedule[x]['schedule'][y].working_days[z].slots = timeStops;
                //timeStops.pop();
  
                for(let i = 0; i<schedule[x]['schedule'][y].working_days[z].config.length; i++) {    
                  schedule[x]['schedule'][y].working_days[z].config[i].num_of_appointment = schedule[x]['schedule'][y].working_days[z].slots.length 
              }
                console.log("chetan++"+timeStops.length);
              }
            }
          }
        }
       
      }
   
  
    //console.log("this config", this.AppointmentConfig);
  }
}
