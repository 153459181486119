import { Component, Input } from '@angular/core';
import Chart, { Plugin, plugins } from 'chart.js/auto';

@Component({
  selector: 'app-popular-topic-graph',
  templateUrl: './popular-topic-graph.component.html',
  styleUrls: ['./popular-topic-graph.component.scss']
})
export class PopularTopicGraphComponent {
  chart: any;
  canvas : any;
  @Input() data:any = {};
  ngOnInit(): void {
    const canvas = <HTMLCanvasElement> document.getElementById('topics');
    const ctx:any = canvas.getContext('2d');
    const height = ctx.height;
    let chartStatus = Chart.getChart("topics"); // <canvas> id
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }

    const gradient = ctx.createLinearGradient(0, 0, 0, 240);
    gradient.addColorStop(1, "rgba(8 251 247 / 10%)");
    gradient.addColorStop(0, "rgba(67 204 213)");
    var keys = [];
    var values = [];
    if(this.data)
    {
    for (var key in this.data) {
      keys.push(key);
        values.push(this.data[key])
    }
   // console.log(keys)
    this.chart = new Chart(ctx, {
      type: 'bar', //this denotes tha type of chart

      data: {// values on X-Axis
        labels: keys,
        
	       datasets: [
          {
            label:"Popular Topics",
            data:values,
            borderColor:"rgba(67 204 213)",
            borderWidth:0,
            backgroundColor:['#E85E55','#6FDC54','#18C1CC','#EBC43C','#F0950C'],
          }
          
        
        ]
      },
      options: {
        plugins: {
          legend: {
              display: false
          },
      },
        indexAxis: 'y',
        aspectRatio:2.5,
        scales: {
          y: {
            grid: {
              display:false,
            }
          },
          x: {
            grid: {
              display:false,
            }
          }
        }
      }
      
    });
  }


  }
}
