import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LeadService {

  constructor(private http: HttpClient) { }

  getAllData(data:any): Observable<any> {
    return this.http.post(environment.getAllLeads,data);
  }
  addLeadData(data:any): Observable<any> {
    return this.http.post(environment.createLead,data);
  }
  getLead(data:any): Observable<any> {
    return this.http.post(environment.getLead,data);
  }
  updateLead(data:any): Observable<any> {
    return this.http.post(environment.updateLead,data);
  }
  addLeadFollowUp(data:any): Observable<any> {
    return this.http.post(environment.createleadsfollowup,data);
  }
}
