import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BillingService } from 'src/app/private/services/billing.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import Receipt from 'src/app/utils/printLayout/GenerateReceipt';

@Component({
  selector: 'app-opd-billing',
  templateUrl: './opd-billing.component.html',
  styleUrls: ['./opd-billing.component.scss']
})
export class OpdBillingComponent {
  @Input() titleOfHead: any = {
    title: '',
    root: 'OPD Billings'
  };
  @Input() patient_id:any
  @Input() app_id:any
  @Input() app_data:any
  @Input() patient:any
  isNaN: Function = Number.isNaN;
  billingCategoryList:any = [];
  actionType:any = "";
  discountType:any = "%";
  loadingSpinnerTax:boolean = false;
  loadingSpinnerCat:boolean = false;
  loadingSpinnerItem:boolean = false;
  loadingSpinnerPayment:boolean = false;
  loadingSpinnerCreditNote:boolean = false;
  billingMasterPriceList:any = [];
  billing:Biiling = new Biiling();
  billingItemData:BiilingItem[] = [];
  payment_methods:any = [];
  taxes:any = [];
  totalDiscount:any = 0;
  totalTax:any = 0;
  totalPrice:any = 0;
  billingList:Biiling[] = [];
  prevBillingList:Biiling[] = [];
  btnLoading:boolean = false;
  buttonTitle = "Print Receipt"
  btnSaveLoading:boolean = false;
  buttonSaveTitle = "Submit"
  btnLoadingRecNo:boolean = false;
  buttonTitleRecNo:any = "Generate Bill & Print";
  billingItem:BiilingItem = new BiilingItem();
  current_app_id:any;
  current_patient_id:any
  insuranceCredit:boolean = false;
  creditOrg:any = [];
  taxSelected:any = {value:0,title:""}
  loading:boolean = false;
  useBalance:boolean = false;
  refundItems:any = [];
  refundNote:any = "";
  payment_mode:any = ""
  constructor(public route:ActivatedRoute,private toast:ToastrService,private localstorage:LocalStorageService, private api:BillingService, private modalService: NgbModal,private offcanvasService:NgbOffcanvas)
  {
    this.route.params.subscribe(params =>
      {
        this.current_patient_id = params['id']
        this.current_app_id = params['app_id']
      })
  }

  
  ngOnInit(): void {
    // checking idf router params contains apointments or not
    this.route.data.subscribe(data => {
      console.log(data)
       if(data['dashabord']== 'opd'){
        this.titleOfHead.title = "OPD"
       }else{
        this.titleOfHead.title = "Appointments"
       }
    });
  }

  selectItem(event:any)
  {
  //  let selectedItem =  this.billingMasterPriceList.find((item:any)=>{ return item.id == event.target.value})
  //  let selectedCategory =  this.billingCategoryList.find((item:any)=>{ return item.id == this.billingItem.cat_id})
  //  console.log(selectedItem)

   //this.addItem(event);


   let exist = this.billingItemData.find((item:any)=> item.item_id == this.billingItem.item_id)
   if(!exist)
   {
    this.billingItem.item_code = event.item_code;
    this.billingItem.item_id = event.id;
    this.billingItem.item_name = event.item;
    this.billingItem.item_price =  event.price;
    this.billingItem.cat_name = event.categoryprice.name;
    this.billingItem.qty = 1
    this.billingItem.subtotal =  event.price;
    this.billingItem.total = event.price;

     this.billingItemData.push(this.billingItem);
     this.billingItem = new BiilingItem();
     this.caluclateValues();
   }
   else
   {
     this.toast.error("This Particular is already added")
   }
   

  }
  editItem(item:any)
  {
    this.billingItem = item;
  }
  removeItem(item:any)
  {
    this.billingItemData = this.billingItemData.filter((row:any)=> row.item_id != item.item_id );
    this.caluclateValues();
  }
  getTax(event:any,_item:any) {
   // alert(event.target.value)
     let text = this.taxes.find((item:any)=> item.tax_value == event.target.value)
     _item.tax = event.target.value
     _item.taxName =  (text) ? text["tax_name"] : ""
     this._calculate(_item);
  }
  _calculate(_item:any) {

     _item.subtotal = _item.item_price * _item.qty;
     //console.log(_item.discount)
       if(_item.discount>=0)
        {
          let discountValue = 0;
          if(_item.discount_type=='%') {
            discountValue = (_item.discount > 0) ? (_item.subtotal) * (_item.discount/100) : 0
          }
          else
          {
            discountValue = _item.discount
          }
          console.log(discountValue)
         // console.log(_item.subtotal)
          if(discountValue <= _item.subtotal ) {
           
            _item.discounted_amount = _item.subtotal - discountValue  ;
            _item.discount_value = discountValue;
            _item.subtotal =  _item.subtotal - discountValue
          }
          else {
            this.toast.error('Discount amount should equal or less than item amount')
          }
 
        }
        _item.total = _item.subtotal

        if(_item.tax >=0)
          {
            let taxValue:Number = 0;
            taxValue = (_item.tax >= 0) ? (_item.total) * (_item.tax/100) : 0
            _item.taxAmount = taxValue
            _item.total = _item.total + Number(_item.taxAmount)
            //alert(taxValue)
          }
//Calculate Total Billing Items
    let discount = 0;
    let _tax = 0;
    let price = 0;
    let subtotal = 0;
    let item_discount = 0;

     this.billingItemData.forEach((element:any) => {
          discount = discount + Number(element.discount);
          _tax = _tax + Number(element.taxAmount);
          price = price + Number(element.item_price);
          subtotal = price * Number(element.qty) ;
          item_discount = item_discount + Number(element.discount_value)
    });
    //alert(_tax)
    let percentage = ((item_discount)/subtotal * 100).toFixed(2)
    this.billing.subtotal = subtotal;
    this.billing.amount =  subtotal - item_discount;
    this.billing.total_tax = _tax;
    this.billing.discount = {amount:item_discount || 0,percentage:percentage};
    this.billing.amount = this.billing.amount + Number(_tax);
  }

  addItem(event:any)
  {
    if(this.billingItem.item_name == "")
    {
      this.toast.error("Add billing item ")
    }
    else
    {
      // if(this.billingItem.tax)
      // {
      //   let taxValue:any;
      //   taxValue = (this.billingItem.tax / 100) * this.billingItem.item_price
      //   this.billingItem.taxType = this.billingItem.tax+"%"
      //   this.billingItem.tax = taxValue;
      //   this.billingItem.item_price = this.billingItem.item_price
      // }

    
        // this.billingItem.subtotal = this.billingItem.item_price * this.billingItem.qty ;
        // if(this.billingItem.discount>0)
        // {
        //   let discountValue = 0;
        //   if(this.discountType=='%') {
        //     discountValue = (this.billingItem.discount > 0) ? (this.billingItem.subtotal) * (this.billingItem.discount/100) : 0
        //   }
        //   else
        //   {
        //     discountValue = this.billingItem.discount
        //   }
        //   if(discountValue <= this.billingItem.subtotal ) {
        //     this.billingItem.discounted_amount = this.billingItem.subtotal - discountValue  ;
        //     this.billingItem.discount_value = discountValue;
        //   }
        //   else {
        //     this.toast.error('Discount amount should equal or less than item amount')
        //   }
 
        // }




      let exist = this.billingItemData.find((item:any)=> item.item_id == this.billingItem.item_id)
      if(!exist)
      {
        this.billingItemData.push(this.billingItem);
        this.billingItem = new BiilingItem();
        this.caluclateValues();
      }
      else
      {
        this.toast.error("This Particular is already added")
      }

    }


  }
  
 loadTaxes () : Promise<any> 
  {
    return new Promise((resolve, reject) => {
      let param = {
        hospital_id:this.localstorage.getHospitalId()
      }
      this.loadingSpinnerTax = true
      this.api.getTaxes(param).subscribe({
        next:(res)=>{
          if(res){
            this.taxes = res;
            this.loadingSpinnerTax = false
            resolve(this.taxes)
          }
        },
        error:(e)=>{
          this.loadingSpinnerTax = false
          reject(e);
        }
      })
    })
    
  }
  loadPaymentMethods()
  {
    let param = {
      hospital_id:this.localstorage.getHospitalId()
    }
    this.loadingSpinnerPayment = true
    this.api.getPaymentMethods(param).subscribe({
      next:(res)=>{
        if(res){
          this.payment_methods = res;
          this.loadingSpinnerPayment = false
        }
      },
      error:(e)=>{
        this.loadingSpinnerPayment = false
      }
    })
  }
  caluclateValues()
  {
    let discount = 0;
    let tax = 0;
    let price = 0;
    let subtotal = 0;
    let item_discount = 0;

    this.billingItemData.forEach((element:any) => {

      discount = discount + Number(element.discount);
      tax = tax + Number(element.taxAmount);
      price = price + Number(element.item_price);
      subtotal = price * Number(element.qty) ;
      item_discount = item_discount + Number(element.discount_value)

    });

    let percentage = ((item_discount)/subtotal * 100).toFixed(2)
    this.billing.subtotal = subtotal;
    this.billing.amount =  subtotal - item_discount;
    this.billing.discount = {amount:item_discount || 0,percentage:percentage};
  }
  loadBillingCategory()
  {
    this.loadingSpinnerCat = true
    this.api.getAllPriceCategory().subscribe({
      next:(res)=>{
        if(res)
          {
            this.loadingSpinnerCat = false
            this.billingCategoryList = res;
          }
        
      },
      error:(e)=>{
        this.loadingSpinnerCat = false
      }
    })
  }
  loadPriceMasterItems(event:any)
  {
    let param = {
       hospital_id:this.localstorage.getHospitalId(),
       center_id:this.localstorage.getCenterId(),
       cat_id:event.target.value
    }
    this.loadingSpinnerItem = true
    this.api.getAllPriceMaster(param).subscribe({
      next:(res)=>{
        if(res){
          this.loadingSpinnerItem = false
          this.billingMasterPriceList = res;
        }
         
      },
      error:(e)=>{
        this.loadingSpinnerItem = false
      }
    })
  }
  clear()
  {
    this.billing =  new Biiling();
    this.billingItem =  new BiilingItem();
    this.billingItemData = [];
    this.caluclateValues();
   this.modalService.dismissAll();
   this.actionType  = "";
  }
  refundInvoice(item:any,content:any) {
    this.modalService.open(content,  {  size:'lg', scrollable: true,ariaLabelledBy: 'modal-basic-title' });
    this.billing = item;

    let creditInvoiceItem:any = [];
    if(item.creditnotes.length > 0){
      item.creditnotes.forEach((cr:any) => {
       cr.billing_items.forEach((r:any) => {
        creditInvoiceItem.push(r)
       });
      });
    }
    let newArray = item.billings_items.filter((invoice:any) => !creditInvoiceItem.some((crNote:any) => invoice.item_id === crNote.item_id));
    console.log(newArray)
 
    this.billingItemData = newArray|| []
  }
  async editInvoice(item:any,content:any)
  {
    this.actionType = "edit"
    //this.loadBillingCategory();
    this.loadPaymentMethods();

    if(item.payment_method=='Bill'){
      this.getCreditOrganization();
    }
    this.modalService.open(content,  { fullscreen:true, size:'xl', scrollable: true,ariaLabelledBy: 'modal-basic-title' });
    await this.loadTaxes();
    this.billing = item;
    this.billingItemData = item.billings_items || []
    //this.caluclateValues();
  }
  generateInvoiceNo(content:any,item:any)
  {
    this.modalService.open(content,  { size:'sm', scrollable: false,ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      if (result === 'yes') {
        this.btnLoadingRecNo = true;
        this.buttonTitleRecNo = "Loading"
         let param = {
          hospital_id:item.hospital_id,
          center_id:item.center_id,
          id:item.id,
          patient_id:item.patient_id
         }
           this.api.generateReceiptNo(param).subscribe({
            next:(res)=>{
              if(res)
              {
                item.receipt_no = res;
                let param = {
                  hospital_id:item.hospital_id,
                  center_id:item.center_id,
                  id:item.id,

                }
                this.api.getReceiptData(param).subscribe({
                  next:(res)=>{
                    res.patient_name = this.app_data.fname +" "+this.app_data.lname
                    res.age = this.app_data.age
                    res.gender = this.app_data.gender

                    this.btnLoadingRecNo = false;
                    this.buttonTitleRecNo = "Generate Bill & Print"
                    Receipt(res);
                  },
                  error:(e)=>{
                    console.log(e)
                    this.btnLoadingRecNo = false;
                    this.buttonTitleRecNo = "Generate Bill & Print"
                  }
                })
              }
              else
              {
                this.btnLoadingRecNo = false;
                this.buttonTitleRecNo = "Generate Bill & Print"
              }

            },
            error:(e)=>{
              console.log(e)
            }
          })
      }
    }, (reason) => {

    });

  }
  async OpenNewBill(content:any)
  {
    this.actionType  = "add";
    //this.loadBillingCategory();
    this.clearRefund();
    this.loadPaymentMethods();
   
;  
    this.modalService.open(content,  {   fullscreen:true,size:'xl', scrollable: true,ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      if (result === 'Cross click') {


      }

    }, (reason) => {

    });
    await this.loadTaxes();
  }
  ngAfterViewInit()
  {
    this.loadBillingBypatientId();
  }
  loadBillingBypatientId()
  {

    let param = {
      patient_id:this.current_patient_id,
      app_id:this.current_app_id,
      hospital_id:this.localstorage.getHospitalId(),
      center_id:this.localstorage.getCenterId(),
      billing_type:'OPD'
      
    }
    
    this.loading = true;
   this.api.getBillingListByPatient(param).subscribe({
    next:(res)=>{
      this.billingList = res;
      this.prevBillingList = res.slice(1);
        //console.log(this.billingList)
        this.loading = false;
    },
    error:(e)=>{
      this.loading = false;
      console.log(e)
    }
  })
  }
  CreateBill()
  {
    if(this.billingItemData.length == 0)
    {
      this.toast.error("Add Atleast 1 Billing Particulars")
    }
    else if(!this.billing.status)
    {
      this.toast.error("Select Status")
    }
    else if(!this.billing.payment_method)
    {
      this.toast.error("Select Payment Method")
    }
    else
    {
      this.buttonSaveTitle = "Loading";
      this.btnSaveLoading = true;
      this.billing.billings_items = this.billingItemData;
      this.billing.hospital_id =  this.localstorage.getHospitalId();
      this.billing.center_id =  this.localstorage.getCenterId();
      this.billing.patient_id = this.patient_id;
      this.billing.app_id = this.app_id
      this.billing.opd_no = this.app_data?.appointment?.opd_number
      //console.log(this.billing)
      if(this.actionType=='add')
      {
        this.api.createBilling(this.billing).subscribe({
          next:(res)=>{

            this.buttonSaveTitle = "Submit";
            this.btnSaveLoading = false;
            this.toast.success("Bill Added Successfully")
            this.loadBillingBypatientId();
            this.clear()
          },
          error:(e)=>{
            console.log(e)
            this.buttonSaveTitle = "Submit";
            this.btnSaveLoading = false;
          }
        })
      }
      else if(this.actionType=='edit')
      {
        this.api.updateBilling(this.billing).subscribe({
          next:(res)=>{

            this.buttonSaveTitle = "Submit";
            this.btnSaveLoading = false;
            this.toast.success("Bill Updated Successfully")
            this.loadBillingBypatientId();
            this.clear()
          },
          error:(e)=>{
            console.log(e)
            this.buttonSaveTitle = "Submit";
            this.btnSaveLoading = false;
          }
        })
      }

    }

  }
  printInvoice(item:any)
  {
    this.btnLoading = true;
    this.buttonTitle = "Loading"
    let param = {
      hospital_id:item.hospital_id,
      center_id:item.center_id,
      id:item.id,

    }
    this.api.getReceiptData(param).subscribe({
      next:(res)=>{
        res.patient_name = this.app_data.fname +" "+this.app_data.lname
        res.age = this.app_data.age
        res.gender = this.app_data.gender
        this.btnLoading = false;
        this.buttonTitle = "Print Receipt"
        res.billing_date = this.formatDateToDDMMYYYY(res.billing_date)
        res.uhid = this.app_data.old_patient_record_number || ""
        res.patient_address = this.app_data.address
        res.pId = this.app_data.pId || "N/A"
        Receipt(res);
      },
      error:(e)=>{
        console.log(e)
        this.btnLoading = false;
        this.buttonTitle = "Print Receipt"
      }
    })

  }
   formatDateToDDMMYYYY = (date: string): string => {
    return moment(date).format('DD-MM-YYYY');
  };
  openSelected(item:any)
  {
       let currentBill  = this.billingList.filter((item:any)=>{return item.id = item.id})
       return currentBill[0].billing_date > item.billing_date
  }
  onChangePaymentMethod(event:any)
  {
    if(this.billing.payment_method == 'PARTIAL PAYMENT') {
      this.billing.status = "partial"
    }
    let value:string = event.target.value;
   this.insuranceCredit = (value.toLowerCase() == 'bill') ? true : false;
   if(event.target.value=='Bill')
   {
    this.getCreditOrganization();
    this.billing.status = "paid"
   }
  }
  fnOnCreditOrgChange(event:any)
  {
    let value = event.target.value;
   if(value)
   {
    let row = this.creditOrg.find((item:any)=>  item.id == value);
    this.billing.health_insurance_credit = {id:row.id,name:row.name}
   }
  }
  getCreditOrganization()
  {
    let param = {
      where:{
        hospital_id:this.localstorage.getHospitalId(),
      }
    }
    this.api.getCreditOrganization(param).subscribe({
      next:(res)=>{
        if(res)
        {
          this.creditOrg = res;
        }
      },
      error:(e)=>{}
    })
  }
  refundItem(_item:any,event:any){
    
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      _item.status = "Cancel"
      this.refundItems.push(_item);
    } else {
      const index = this.refundItems.indexOf(_item);
      if (index > -1) {
         _item.status = "Paid"
        this.refundItems.splice(index, 1);
      }
    }
  }
  submitRefund(){
    if(this.refundItems.length==0){
          this.toast.error("No outstanding left for return and cancel")
    }
    else 
    if(this.payment_mode==""){
      this.toast.error("Select payment mode")
}
    else {

      let total_amount  = 0;
      this.refundItems.forEach((element:any) => {
        total_amount = total_amount + Number(element.total);
        
      });
      let creditNote = {
        invoice_number:this.billing.receipt_no,
        app_id: this.billing.app_id,
        invoice_id: this.billing.id,
        patient_id:this.billing.patient_id,
        billing_type:"OPD",
        amount:total_amount,
        reason:this.refundNote,
        hospital_id: this.localstorage.getHospitalId(),
        center_id: this.localstorage.getCenterId(),
        status:"Requested",
        billing_date:moment().format("YYYY-MM-DD"),
        billing_items:this.refundItems,
        billing_total:this.billing,
        payment_mode:this.payment_mode
  
      }
      console.log(creditNote)
      this.loadingSpinnerCreditNote = true;
      this.api.createCreditNote(creditNote).subscribe({
        next:(res)=>{
          if(res) {
            this.loadingSpinnerCreditNote = false;
            this.modalService.dismissAll();
            this.toast.success("Cancel & refund request created successfully")
            this.loadBillingBypatientId();
          }

        },
        error:(e)=>{
          this.loadingSpinnerCreditNote = false;
        }
      })
    }
   
  }
  clearRefund(){
    this.loadBillingBypatientId();
    this.billing =  new Biiling();
    this.billingItem = new BiilingItem();
    this.modalService.dismissAll();
  }

   areAllKeyValuePairsPresent = (billItems: any[], creditNoteItems: any[]): boolean => {
    console.log(billItems)
    console.log(creditNoteItems)
    if (!Array.isArray(billItems) || !Array.isArray(creditNoteItems)) {
      throw new TypeError('Both arguments should be arrays');
    }
  
    return billItems.every(billItems => creditNoteItems.some(creditNoteItems => billItems.item_id === creditNoteItems.item_id));
  };
}

export class Biiling {
  id!:Number;
  creditnotes!:any;
  patient_id:Number = 0;
  opd_no:string = "";
  receipt_no!:string;
  hospital_id:string = "";
  center_id:string = "";
  billing_type:string = "OPD";
  app_id:Number = 0;
  billings_items:any;
  billing_date:any = moment().format("YYYY-MM-DD");;
  amount:any = 0.00;
  subtotal:any = 0.00;
  total_tax:any = 0;
  discount:any =  {
    amount:0,
    percentage:"",
  };
  paid_amount:any = 0.00;
  due_amount:any = 0.00;
  status:any = "";
  payment_method:string = ""
  health_insurance_credit:any = {
    id:"",
    name:""
  };
  remarks:any = "";

}
export class BiilingItem {
  cat_id:string = "";
  cat_name:string = "";
  item_id:string = "";
  item_code:string = "";
  item_name:string = "";
  item_price:any = 0;
  discount_value:any = 0;
  discount:any = 0;
  discounted_amount:any = 0;
  discount_type:any = "%"
  subtotal:any = 0;
  total:any = 0;
  qty:any = 0;
  status:any = "";
  tax:any = 0;
  taxName:any = "";
  taxAmount:any = 0;
}
