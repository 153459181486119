import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { AppointmentService } from '../../services/appointment.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { merge, startWith, switchMap, catchError, map } from 'rxjs';
import { Router } from '@angular/router';
import { IpdAppointmentService } from '../../services/ipdappointment.service';
import { environment } from 'src/environments/environment';
import { MasterService } from '../../services/master.service';
import { UsersService } from '../../services/users.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-ipd',
  templateUrl: './ipd.component.html',
  styleUrls: ['./ipd.component.scss']
})
export class IpdComponent {
  isLoading = false;
  btnLoading:boolean = false;
  totalRows = 0;
  patientRecord:any = {
    prefix:"",
    fname:"",
    lname:"",
    mobile:"",
    email:"",
    patient_id:"",
    age:"",
    gender:"",
    pId:"",
    address:""
  };
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 100];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  dateQuery = new FormControl();
  formAction = "add";
	closeResult = '';
  isSubmitted: boolean = false;
  procedureList:any = [];
  doctorList:any = [];
  diagnosisTemplateURL:any = environment.getDiagnosisTemplate;
  admissionTemplateURL:any = environment.getAdmissionTemplate;
  procedureListURL:any = environment.getAllPriceMaster;
  ipdAppointment:IpdAppointment =  new IpdAppointment();
  @ViewChild("ipdAppointmentForm")
  ipdAppointmentForm!: NgForm;
  @ViewChild('SubmitForm')
  SubmitForm!: ElementRef;
  displayedColumns: string[] = ["pId","ipd","name","doctorInCharge","date","time","status","action"];
  exportColumns:any = ["pId","Ipd No","Name","Doctor In Charge","Date","Time","Status"];
  exportArrayData:any = []
  diagnosisValues:any = [];
  admissionValues:any = [];
  procedureValues:any = [];
  doctorInchargeid:any = "";
  doctorInchargeObject:any = {};
  constructor(private user:UsersService,private router:Router, private toast:ToastrService, private api:IpdAppointmentService,private modalService: NgbModal, private localStorage:LocalStorageService)
  {
    
  }
  onDoctorChange(event:any)
  {
   let id = event.target.value;
   if(id)
   {
    let selectedDoctor = this.doctorList.filter((item:any)=>item.user_id = id);
    this.doctorInchargeObject = selectedDoctor[0]
   }
  }
  ngAfterViewInit() {
    this.loadData();
  }

  searchFilter(event:any){
    this.searchQuery.setValue(event);
  }

  loadData()
  {
    try{
   this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
   merge(this.dateQuery.valueChanges,this.searchQuery.valueChanges, this.sort.sortChange, this.paginator.page).pipe(startWith({}),
   switchMap(()=>{
    let param = {
      where:{hospital_id:this.localStorage.getHospitalId(),center_id:this.localStorage.getCenterId()},
      filter:this.searchQuery.value,
      page:(this.paginator.pageIndex *  this.paginator.pageSize),
      limit:this.paginator.pageSize,
      order:[{col:'date_of_admission',dir:"desc"},{col:'time_of_admission',dir:"desc"}],
    }
    if(this.dateQuery.value){param = {...param,...this.dateQuery.value}}
    return  this.api.getAllIpdAppointmentWithCount(param)
    .pipe(catchError(() => observableOf(null)));
   }),map((response:any)=>{
    if (response === null) {
      return [];
    }
 
    this.totalRows = response.count;
    return response
   })
   ).subscribe({
    next:(data) =>{
   // console.log(data)
   this.exportArrayData = data.rows;
    this.dataSource = new MatTableDataSource<any>(data.rows);;
    },
    error:(e)=>{
     console.error(e)
    },
    complete:()=>{
     
    }
   })
  }
  catch(e){
   console.error(e)
  }
  }
  open(content:any){
    this.loadDoctors();
    this.modalService.open(content, { size:'xl', scrollable: true, centered: true}).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
  }
  closeModal()
  {
    this.modalService.dismissAll()
    this.ipdAppointment = new IpdAppointment();
  }

  loadDoctors()
  {
    let param = {
       hospital_id:this.localStorage.getHospitalId(),
       center_id:this.localStorage.getCenterId(),
       roles:['Doctor', "Ophthalmologist","Surgeon"]
    }
    this.user.getDoctors(param).subscribe({
      next:(res)=>{
         this.doctorList = res;
      },
      error:(e)=>{
        console.log(e)
      }
    })
  }
  dateFilter(query:any){
    this.dateQuery.setValue(query)
  }
  getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}
  
  createIpdAppointment(isValid:boolean)
  {
    console.log("IPD data : ",this.ipdAppointment);
    this.isSubmitted = true;
    if(isValid)
    {
      this.btnLoading = true;
      this.ipdAppointment.reason_for_admission = this.admissionValues
      this.ipdAppointment.diagnosis = this.diagnosisValues
      this.ipdAppointment.procedures = this.procedureValues
      this.ipdAppointment.doctor_incharge = this.doctorInchargeObject
      this.ipdAppointment.hospital_id = this.localStorage.getHospitalId();
      this.ipdAppointment.center_id = this.localStorage.getCenterId();
      //console.log(this.ipdAppointment)

      this.api.createIPDAppointment(this.ipdAppointment).subscribe({
        next:(res)=>{
          if(res)
          {
            this.toast.success("IPD Patient Admitted");
            this.modalService.dismissAll();
            this.ipdAppointment = new IpdAppointment();
            this.isSubmitted = false;
            this.loadData();
            this.btnLoading = false;
          }
        },
        error:(e)=>{
          console.log(e)
          this.btnLoading = false;
        }
      })
    }

  }
  getPatientRecord(data:any)
  {
    this.ipdAppointment.patient_id = data.id
    this.ipdAppointment.prefix = data.prefix
    this.ipdAppointment.fname = data.fname
    this.ipdAppointment.lname = data.lname
    this.ipdAppointment.mobile = data.mobile
    this.ipdAppointment.email = data.email
    this.ipdAppointment.age = data.age
    this.ipdAppointment.gender = data.gender
    this.ipdAppointment.address = data.address
  }
  getAdmissionValues(data:any){this.admissionValues = data}
  getDiagnosisValues(data:any){this.diagnosisValues = data} 
  getProcedureValues(data:any){this.procedureValues = data} 
  goToIPD(item:any)
  {
    this.router.navigate(['main/in-patient/record/'+item.id+'/'+item.ipdappointment.id])
  }

  openReschedule(content:any,item:any)
  {


    this.modalService.open(content,  { size:'sm', scrollable: false,ariaLabelledBy: 'modal-basic-title',centered: true }).result.then((result) => {  
      if (result === 'yes') {  
       // this.deleteSchedule(item.schedule_id)
      }  
    }, (reason) => {  

    });  
  }
  
  cancelAppointmentfn(content:any,item:any)
  {
    this.modalService.open(content,  { size:'sm', scrollable: false,ariaLabelledBy: 'modal-basic-title',centered: true }).result.then((result) => {  
      if (result === 'yes') {  
        let param = {
          patient_id:item.appointment.patient_id,
          app_id:item.appointment.app_id,
          status:"cancelled",
        }
        // this.api.updateAppointment(param).subscribe({
        //   next:(res)=>{
        //     this.modalService.dismissAll();
        //     this.toast.success("Appointment Cancelled Successfully")
        //     this.loadData()
        //   },
          
        //   error:(e)=>{
    
        //   }
        // })
      }  
    }, (reason) => {  

    });  
  }

  discharge(content:any,item:any)
  {
    this.modalService.open(content,  { size:'sm', scrollable: false,ariaLabelledBy: 'modal-basic-title',centered: true }).result.then((result) => {  
      if (result === 'yes') {  
        let param = {
          patient_id:item.appointment.patient_id,
          app_id:item.appointment.app_id,
          status:'check_out',
        }
        // this.api.updateAppointment(param).subscribe({
        //   next:(res)=>{
        //     this.modalService.dismissAll();
        //     this.loadData()
        //     this.toast.success("Appointment Updated Successfully")
        //   },
        //   error:(e)=>{
    
        //   }
        // })
      }  
    }, (reason) => {  

    });  
  }
  checkInIpd(item:any)
  {
    
    if(item.pId=='N/A' || item.ipdappointment.ipd_number=='N/A' || item.appointment.opd_number=='' )
    {
     // alert('if')
      let param = {
        hospital_id:this.localStorage.getHospitalId(),
        center_id:this.localStorage.getCenterId(),
        patient_id:item.id,
        ipd_app_id:item.ipdappointment.id,
        pId:item.pId,
        ipd_number:item.ipdappointment.ipd_number,
        status:"check_in"
      }
      console.log(param)
      this.api.checkInIpdPatientData(param).subscribe({
        next:(res)=>{
          if(res)
          {
            this.toast.success('Patient Checked IN IPD')
            setTimeout(() => {
              this.router.navigate(['main/in-patient/record/'+item.id+'/'+item.ipdappointment.id])
            }, 500);
          
          }
        },
        error:(e)=>{}
      })
    }
    else
    {
     // alert('else')
     this.router.navigate(['main/appointments/record/'+item.id+'/'+item.appointment.app_id])
    }
   
  }
  checkIn(content:any,item:any)
  {
    this.modalService.open(content,  { size:'sm', scrollable: false,ariaLabelledBy: 'modal-basic-title',centered: true }).result.then((result) => {  
      if (result === 'yes') {  
        this.checkInIpd(item);
      }  
    }, (reason) => {  

    });  
  }

  export(header:any)
  {
    //console.log(header)
    let excelRowData:any = [];
    this.exportArrayData.forEach((element:any) => {
     let status = "";
     if( element.ipdappointment.status=="booked")
     {
       status = element.ipdappointment.status.replaceAll('booked','Booked')
     }
     else if( element.ipdappointment.status=="check_in" )
     {
       status = element.ipdappointment.status.replaceAll('check_in','Checked In')
     }
     else if( element.ipdappointment.status=="rescheduled" )
     {
       status = element.ipdappointment.status.replaceAll('rescheduled','Rescheduled')
     }
     else if( element.ipdappointment.status=="discharge")
     {
       status = element.ipdappointment.status.replaceAll('discharge','Discharged')
     }

     else if( element.ipdappointment.status=="cancelled")
     {
       status = element.ipdappointment.status.replaceAll('cancelled','Cancelled')
     }

     excelRowData.push({0:element.pId,1:element.ipdappointment.ipd_number,2:element.fname+" "+element.lname,3:element.ipdappointment.doctor_incharge.fname+" "+element.ipdappointment.doctor_incharge.lname,4:element.ipdappointment.date_of_admission,5:element.ipdappointment.time_of_admission,6:status})
   });

    let excelData = [];
    let excelHeader = [];
      for (let i = 0; i < excelRowData.length; i++) {
        let element = excelRowData[i];
        let obj:any = {}
        for (let index = 0; index < header.length; index++) {
          let key = header[index]['key'];
          obj[key] = element[key]
        }
        excelData.push(obj)
      }
      for (let index = 0; index < header.length; index++) {
        let value = header[index]['value'];
        excelHeader.push(value)
      }
    //console.log(excelData)
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, [excelHeader]);
    XLSX.utils.sheet_add_json(ws,excelData, { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth()+1;
    let year = date.getFullYear();

  XLSX.writeFile(wb, "In_Patient_"+day+"_"+month+"_"+year+"_"+date.getTime()+".csv");
  }
}

export class IpdAppointment {
  prefix:string = "";
  fname:string = "";
  lname:string = "";
  mobile:string= "";
  email:string= "";
  age:string = "";
  gender:string = "";
  pId:string = "";
  address:string = "";
  patient_id:Number = 0;
  ipd_number:string = "";
  date_of_admission:string = "";
  time_of_admission:string = "";
  day_care:boolean = true;
  reason_for_admission:[] = [];
  diagnosis:[] = [];
  procedures:[] = [];
  room_no:string = "";
  room_type:string = "";
  doctor_incharge:[] = [];
  significant_finding:string = "";
  lama:string = "";
  mlc:string = "";
  hospital_id:Number = 0;
  center_id:Number = 0;
  status:string = "booked";
}

function observableOf(arg0: null): any {
 // throw new Error('Function not implemented.');
}

