import { Component, ElementRef, HostListener, Input, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { IpdchartService } from 'src/app/private/services/ipdchart.service';
import { MasterService } from 'src/app/private/services/master.service';
import { SupplyService } from 'src/app/private/services/supply.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { UsersService } from 'src/app/private/services/users.service';

@Component({
  selector: 'app-intra-op',
  templateUrl: './intra-op.component.html',
  styleUrls: ['./intra-op.component.scss']
})
export class IntraOpComponent {
  @Input() titleOfHead: any = {
    title: 'IPD',
    root: 'Intraoperative'
  };
  @Input() patient:any;
  btnLoading:boolean = false;
  supplyCategory:any = [];
  procedure_step:any = "";
  surgeonInchargeObject:any = {};
  doctorList:any = [];
  intra_op:any = {
    anesthesia_vital_signs:[],
    surgical_procedures_steps:[], 
    surgical_items:[], 
    remarks:"",
    surgeon:"",
    procedure_date:"",
    surgery_start_time:"",
    surgery_end_time:"",
    section:"",
    capsulotomy:"",
    blue_used:"",
    ctr:"",
    vitrectomy:"",
    triamcinolone:"",
    adrenaline:"",
    complication:"",
    sutures:"",
    iol_id:"",

  }
  current_patient_id:any;
  ipd_app_id:any;
  id:any;
  time_slots:any = []
  supplyList:any = [];
  isDropdownOpen :boolean = false;
  activeIndex = -1;
  selectedItem: any = "Select Item";
  selectedQty:any = 0;
  loadingBar:boolean = false;
  addMore:boolean = false;
  @ViewChildren('dropdownItem') dropdownItems!: QueryList<ElementRef>;
   constructor(private user:UsersService, public route:ActivatedRoute,private ipdChartData:IpdchartService,
    private localStorage:LocalStorageService,
    private master:MasterService,
    private toast:ToastrService,
    private supply:SupplyService
  ){
    this.route.params.subscribe(params =>
      {
        this.current_patient_id = params['id']
        this.ipd_app_id = params['ipd_app_id']
      })
    //console.log(this.getTimeSlots("10:00","13:00",15))
    
   }



   updateSupplyQty(id: any,qty:any,action:any): Promise<any> {

    let param = {
      where: {
        supply_id: id,
        hospital_id:this.localStorage.getHospitalId()
      },
      item:{
          quantity: qty,
          condition: action
      }
    };
  
    return new Promise((resolve, reject) => {
      this.master.updateStock(param).subscribe({
        next: (res) => {
          if (res) {
            resolve(res); // Resolve the Promise with the response data
          } else {
            reject('No Record updated'); // Reject the Promise if response is empty
          }
        },  
        error: (err) => {
          reject(err); // Reject the Promise if an error occurs
        }
      });
    });
  }
   onSelect(item: any) {
     this.selectedItem = item;
     this.isDropdownOpen = false; 
   }
   async ngAfterViewInit()
   {
     this.getPatientProcedureData();

     this.supplyCategory = await this.loadInventoryCategories('supply')
     this.loadDoctors();
   }

   loadDoctors()
   {
     let param = {
        hospital_id:this.localStorage.getHospitalId(),
        center_id:this.localStorage.getCenterId(),
        roles:['Surgeon']
     }
     this.user.getDoctors(param).subscribe({
       next:(res)=>{
          this.doctorList = res;
       },
       error:(e)=>{
         console.log(e)
       }
     })
   }

   onDoctorChange(event:any)
   {
    let id = event.target.value;
    if(id)
    {
     let selectedDoctor = this.doctorList.filter((item:any)=>item.user_id = id);
     this.surgeonInchargeObject = selectedDoctor[0]
    }
   }

   toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    if (this.isDropdownOpen) {
      this.activeIndex = 0;
      this.focusItem(this.activeIndex);
    } else {
      this.activeIndex = -1;
    }
  }
  focusItem(index: number) {
    const itemsArray = this.dropdownItems.toArray();
    if (itemsArray[index]) {
      itemsArray[index].nativeElement.focus();
    }
  }
  async deleteSurgItem(item:any){
    console.log(item)
    //let new_qty = item.qty = 
    await this.updateSupplyQty(item.supply_id,item.qty,'add');
    this.intra_op.surgical_items = this.intra_op.surgical_items.filter((row:any)=>{ return row.supply_id != item.supply_id})
    this.SaveProcedureData()
  }
  async deleteProcedureItem(item:any){
    //let new_qty = item.qty = 
   // await this.updateSupplyQty(item.supply_id,item.qty);
    this.intra_op.surgical_procedures_steps = this.intra_op.surgical_procedures_steps.filter((row:any)=>{ return row != item})
  }
  
  @HostListener('document:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (!this.isDropdownOpen) return;

    if (event.key === 'ArrowDown') {
      this.activeIndex = (this.activeIndex + 1) % this.supplyList.length;
      this.focusItem(this.activeIndex);
      event.preventDefault();
    } else if (event.key === 'ArrowUp') {
      this.activeIndex = (this.activeIndex - 1 + this.supplyList.length) % this.supplyList.length;
      this.focusItem(this.activeIndex);
      event.preventDefault();
    } else if (event.key === 'Enter') {
      this.onSelect(this.supplyList[this.activeIndex]);
      event.preventDefault();
    } else if (event.key === 'Escape') {
      this.isDropdownOpen = false;
      this.activeIndex = -1;
      event.preventDefault();
    }
  }
  checkQty(event:any){
    if(event.target.value && this.selectedItem && this.selectedItem.available_quantity < event.target.value  ) {
      event.target.value  = ""
    }
    
    }
   onSelectCategory(event:any) {
   this.loadingBar = true;
    let param = {
      where:{
        hospital_id:this.localStorage.getHospitalId(),
        category:event.target.value
      }
    }
     this.master.getAllSupply(param).subscribe({
      next:(res)=>{
        if(res.length > 0){
           this.selectedItem = "Select Item"
          this.supplyList = res;
          this.loadingBar = false;
        }
        else {
          this.selectedItem = "No Item Found"
          this.loadingBar = false;
          this.supplyList = [];
        }
      },
      error:(e)=>{
        this.loadingBar = false;
      }
     })
   }
   addProcedureStep() {
    if(this.procedure_step==""){
      this.toast.error("Enter Procedure step")
    }else {
      this.intra_op.surgical_procedures_steps.push(this.procedure_step)
      this.procedure_step = ""
    }
    

   }
   async addInstruments(){
    if(this.selectedItem=="Select Item"){
      this.toast.error("Select Surgical item")
    }
    else if(this.selectedQty==0){
      this.toast.error("Enter Quantity")
    }
    
    else {
      let qty = this.selectedQty
      this.addMore = true;
     // console.log(this.selectedItem)
      let item = {
      date_added:moment().format("YYYY-MM-DD"),
      supply_id:this.selectedItem.supply_id, 
      qty:this.selectedQty,
      item_name:this.selectedItem.supply_name,
      item_price:this.selectedItem.unit_price
      }
      await this.updateSupplyQty(this.selectedItem.supply_id,this.selectedQty,'remove');
      let index   = this.intra_op.surgical_items.findIndex((item:any)=>{ return item.supply_id == this.selectedItem.supply_id })
        // Update the object
        console.log(index)
      if (index !== -1) {
        let updatedQty =  parseInt(this.intra_op.surgical_items[index].qty) + parseInt(this.selectedQty)
        if(this.selectedItem.available_quantity > updatedQty){
          this.intra_op.surgical_items[index].qty = updatedQty;
        }
       
     
       //console.log(newArray)
      }
      else {
        this.intra_op.surgical_items.push(item)
      }
      this.addMore = false;
      this.selectedQty = "";
      this.SaveProcedureData();
       //this.procedure_step = ""
    }

   }

   loadInventoryCategories(type: any): Promise<any> {
    let param = {
      where:{cat_type: type}
    };
  
    return new Promise((resolve, reject) => {
      this.master.getInventoryCategory(param).subscribe({
        next: (res) => {
          if (res) {
            resolve(res); // Resolve the Promise with the response data
          } else {
            reject('No data found'); // Reject the Promise if response is empty
          }
        },  
        error: (err) => {
          reject(err); // Reject the Promise if an error occurs
        }
      });
    });
  }
   getPatientProcedureData()
{
  // console.log(this.app_data)

 let param = {
  where:{
 hospital_id:this.localStorage.getHospitalId(),
  center_id:this.localStorage.getCenterId(),
  patient_id:this.current_patient_id,
  },
  attributes:['id','ipd_app_id','hospital_id','patient_id','center_id','procedures','intra_op']
}
this.ipdChartData.getAllIpdChartData(param).subscribe({
  next:(res)=>{
   if(res)
   {
    // this.count = res.filter((item:any)=>{return item.investigations}).length;
   //  this.investigationListData = res.filter((item:any)=>{return item.app_id != this.ipd_app.appointment.app_id && item.investigations });
     let existingObject  = res.find((item:any)=>{return item.ipd_app_id == this.ipd_app_id })

     //console.log(existingObject)
     if(existingObject!=undefined)
     {
       this.id =  existingObject.id || undefined;

       if(existingObject.procedures.anesthesia_details){
        let anesthesia_details = existingObject.procedures.anesthesia_details;
       // console.log(existingObject)
        this.time_slots = this.getTimeSlots(anesthesia_details.start_time,anesthesia_details.end_time,anesthesia_details.duration)
          this.intra_op.anesthesia_vital_signs = this.time_slots.map((slot:any) => ({
            time: slot,
            heart_rate:"",
            bp:"",
            rr:"",
            spo2:"",
            temperature:"",
          }));
          this.intra_op.procedure_date = existingObject.procedures.procedure_date;
          this.intra_op.surgery_start_time = existingObject.procedures.procedure_time;
       }
       if(existingObject.intra_op){
          this.intra_op = {...this.intra_op,...existingObject.intra_op}
       }
      
     }


   }

  },
  error:(e)=>{

  }
})
}

   getTimeSlots(startTime: string, endTime: string, duration: number): string[] {
    const slots: string[] = [];
    const start = this.parseTime(startTime);
    const end = this.parseTime(endTime);

    let current = new Date(start);

    while (current <= end) {
      slots.push(this.formatTime(current));
      current = new Date(current.getTime() + duration * 60000); // Add duration in milliseconds
    }

    return slots;
  }

  private parseTime(time: string): Date {
    const [hours, minutes] = time.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
  }

  private formatTime(date: Date): string {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  SaveProcedureData() {

    if(this.id)
      {
       this.btnLoading = true;
       let param = {
         hospital_id:this.localStorage.getHospitalId(),
         center_id:this.localStorage.getCenterId(),
         id:this.id,
         ipd_app_id:this.ipd_app_id,
         intra_op:this.intra_op,
         }
    
       console.log(param)
        this.ipdChartData.updateIpdChartData(param).subscribe({
         next:(res)=>{
           if(res)
           {
             this.btnLoading = false;
             this.toast.success("Data Saved successfully...")
    
           }
         },error:(e)=>{
           this.btnLoading = false;
         }
        })
      }
      else{
        this.btnLoading = true;
       let param = {
         hospital_id:this.localStorage.getHospitalId(),
         center_id:this.localStorage.getCenterId(),
         patient_id:this.current_patient_id,
         ipd_app_id:this.ipd_app_id,
         intra_op:this.intra_op,
         }
    
         console.dir(param)
        this.ipdChartData.createIpdChartData(param).subscribe({
         next:(res)=>{
           if(res)
           {
             this.btnLoading = false;
             this.toast.success("Data Saved successfully...")
    
           }
         },error:(e)=>{
           this.btnLoading = false;
         }
        })
      }

  }
}
