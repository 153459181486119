import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateFilterComponent } from '../../ui/date-filter/date-filter.component';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Subject, debounceTime } from 'rxjs';
import { MatDateRangePicker } from '@angular/material/datepicker';
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  
  DateFilterComponent = DateFilterComponent;
  showFilter:boolean = false;
  isCustomFilter:boolean = false;
  activeFilter:string = 'gender';
  FilterdateStart:any = null;
  Filterdateend:any = null;
  isActive: string = 'Add New';
  isGroupActive: string = '';
  filterType:any = "";
  SearchInput:any;
  errorMsg:any
  placeHolderText = "Search...."

  @Input() topBarTitle !: string;
  @Output("addToCart") addToCart: EventEmitter<any> = new EventEmitter();
  @Output("create") Create: EventEmitter<any> = new EventEmitter();
  @Output("ExportData") ExportData: EventEmitter<any> = new EventEmitter();
  @Output("ResetDateFilter") ResetDateFilter: EventEmitter<any> = new EventEmitter();
  @Output("ResetFilter") ResetFilter: EventEmitter<any> = new EventEmitter();
  @Output("DateFilter") DateFilter: EventEmitter<any> = new EventEmitter();
  @Output("searchFilter") searchFilter: EventEmitter<any> = new EventEmitter();
  @Output("searchPatientFilter") searchPatientFilter: EventEmitter<any> = new EventEmitter();
  @Output("openList") openList: EventEmitter<any> = new EventEmitter();
  @Output("customSearchFilter") customSearchFilter: EventEmitter<any> = new EventEmitter();
  @Output("showFilterData") showFilterData: EventEmitter<any> = new EventEmitter();
  @Input() title = "";
  @Input() isDatefilter = false;
  @Input() isExport = false;
  @Input() isAdd = false;
  @Input() isCart = false;
  @Input() showFilterOption = true;
  @Input() showPatientFilterOption = true;
  @Input() isFilter = true;
  @Input() filterArray:any = [];
  @Input() customFilterArray:any = [];
  @Input() exportArray:any = [];
  @Input() cartArray:any = [];
  check_true:boolean = false;
  filterCol:any = [];
  actionButton:any = "search"
  toggleExport:boolean = false;
  selectedFilter:any = "Doctors"
  userIdsFilter:any = []
  dept_idFilter:any = []
  filtersObject:any = {};
  selectedRadioValue:any = "";
  txtQueryChanged: Subject<string> = new Subject<string>();
  placeHolderCalender:any = "Pick"

  constructor(private toast:ToastrService,private router : Router) {
    // this.txtQueryChanged.pipe(debounceTime(1000)).subscribe(query=>{
    //   let object = {}
  
    //   if(query=="" || query == undefined)
    //     {
    //       this.toast.error("Enter Name,Email,Mobile,PId,UHID")
    //       this.errorMsg = "Enter Name,Email,Mobile,PId,UHID"
    //       setTimeout(() => {
    //         this.errorMsg = ""
    //       }, 1000);
    //       this.searchFilter.emit('');
       
    //     }
    //     else
    //     {
    //       object = {
    //         'fname':query,
    //         'lname':query,
    //         'mobile':query,
    //         'email':query,
    //         'pId':query,
    //         'old_patient_record_number':query,
    //       }
    //       //object[type] = query;
    //       this.searchFilter.emit(object);
    //       this.actionButton = 'reset'; 
    //     }
    // })
   }
  ngOnInit(): void {
  }
  openPicker(picker: MatDateRangePicker<any>) {
    picker.open();
  }
  searchRecord(query:any){
  
    this.txtQueryChanged.next(query);
  }
  navigateToCart(){
    this.addToCart.emit();
  }
  toggleExportFn()
  {
    this.toggleExport = (this.toggleExport) ? false :  true

    if(this.toggleExport)
    {
      this.isActive = 'Export'
    }
  }
  
  addFilterCol(event:any,i:any)
  {
       if(event.target.checked)
       {
        this.filterCol.push({key:i,value:event.target.value})
       }
       else
       {
        this.filterCol =  this.filterCol.filter(((item:any)=> item.key !=i))
       }
  }

  customFilterUser(event:any,type:any){
    const checkbox = event.target as HTMLInputElement;
      if (checkbox.checked) {
        this.userIdsFilter.push(event.target.value)
      } else {
        const index = this.userIdsFilter.indexOf(event.target.value);
        if (index > -1) {
          this.userIdsFilter.splice(index, 1);
        }
      }
      this.filterSerach()

  }
  customFilterSearchFn(event:any,key:any){
   let object = {key:key,value:event.target.value}
 //  console.log(object)
   this.customSearchFilter.emit(object)
   //this.showFilter = false
  }
  customFilterDept(event:any,type:any){
    const checkbox = event.target as HTMLInputElement;
      if (checkbox.checked) {
        this.dept_idFilter.push(event.target.value)
      } else {
        const index = this.dept_idFilter.indexOf(event.target.value);
        if (index > -1) {
          this.dept_idFilter.splice(index, 1);
        }
      }
      this.filterSerach()
  
   // this.searchFilter.emit(object);
  }
  filterSerach(){

      if(this.userIdsFilter.length > 0) {
        this.filtersObject['user_id'] = this.userIdsFilter;
      }
      if(this.dept_idFilter.length > 0) {
        this.filtersObject['dept_id'] = this.dept_idFilter;
      }

    this.customSearchFilter.emit(this.filtersObject)
  }
  filterSerachClear(){
    this.userIdsFilter = [];
    this.dept_idFilter = [];
    this.filtersObject = [];
    this.customSearchFilter.emit("")
    this.selectedRadioValue = ""
  }
  fnSelectAll(event:any)
  {
    var checkboxes:any = document.getElementsByClassName('filter_check');
       if(event.target.checked)
       {

        for (var i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].type == 'checkbox') {
            console.log(checkboxes.length)
              checkboxes[i].checked = true;
              this.filterCol.push({key:i,value:checkboxes[i].value})
          }
         }
       }
       else
       {
        for (var i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].type == 'checkbox') {
              checkboxes[i].checked = false;
          }
      }
        this.filterCol = [];
       }
  }
  changeFilter(event:any)
  {
    this.isActive= 'Filter'
    this.toggleExport = false;
  }
  resetFilter()
  {
    this.actionButton = 'search'; 
    this.SearchInput = ""
    this.filterType = ""
    this.searchFilter.emit("")
    this.ResetFilter.emit("reset")
  }

  createNewGroup(){
    this.openList.emit();
    this.toggleExport = false;
  }

  createMethod()
  {
    this.Create.emit();
    this.isActive= 'Add New'
    this.toggleExport = false;
  }

  getFilterValue ()
  {
    let type = this.filterType;
    let query = this.SearchInput
    let object:any = {}

    if(false)
    {
      this.toast.error("Select Filter Type")
      this.errorMsg = "Select Filter Type"
      setTimeout(() => {
        this.errorMsg = ""
      }, 1000);
   
    }
  

    else
    {
      if(query=="" || query == undefined)
    {
      this.toast.error("Enter Name,Email,Mobile,PId,UHID")
      this.errorMsg = "Enter Name,Email,Mobile,PId,UHID"
      setTimeout(() => {
        this.errorMsg = ""
      }, 1000);
      this.searchFilter.emit('');
   
    }
    else
    {
      this.filterArray.forEach((element:any) => {
        object[element.key] = query
      });
      this.searchFilter.emit(object);
      console.log("search item !");
      this.actionButton = 'reset'; 
    }

    }
 
  }
  selectRadio(id:any){
    this.selectedRadioValue = id
  }
  exportExcel()
  {
    if(this.filterCol.length == 0)
    {
      this.toast.error("Please select column")
      return;
    }
    this.ExportData.emit(this.filterCol);
    this.toggleExport = false;
   
  }
  applyFilter(event: any) {
    let filterValue = event.target.value;
}
showFilterFn() {

  
this.showFilter = true
this.showFilterData.emit(this.showFilter)
}
hideFilterFn(){
this.showFilter = false
this.showFilterData.emit(this.showFilter)
// this.filterSerachClear();
}
toNormalCapitalization(text:any) {
  return text.toLowerCase().replace(/[_-]/g, ' ', function(a:any) { return a.toUpperCase(); });
}
filterByDateRange()
{
  let start = (this.FilterdateStart!=null) ? new Date(this.FilterdateStart.setHours(0,0,0,0)) :this.FilterdateStart;;
  let end =  (this.Filterdateend!=null) ? new Date(this.Filterdateend.setHours(0,0,0,0)) :this.Filterdateend;

  let startd = moment(start).format("YYYY-MM-DD")
  let endb = moment(end).format("YYYY-MM-DD")
  if(startd!= "Invalid date" && endb!= "Invalid date")
  {
    let object = {startDate:startd,endDate:endb}
    this.DateFilter.emit(object)
  }

}
resetDatePicker()
{
  this.FilterdateStart = ""
  this.Filterdateend = ""
  this.placeHolderCalender = "Pick"
  this.DateFilter.emit("")
  
  this.ResetDateFilter.emit("reset")
}




}
