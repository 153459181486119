import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MasterService } from 'src/app/private/services/master.service';
import { TemplateService } from 'src/app/private/services/template.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-multi-tag-input',
  templateUrl: './multi-tag-input.component.html',
  styleUrls: ['./multi-tag-input.component.scss']
})
export class MultiTagInputComponent implements OnChanges {
  resultArray: any = [];
  TemplateList: any = [];
  displayList: any = [];  
  tempdisplayList: any = [];
  showPopup: boolean = false;
  loadingData: boolean = false;
  checked: any = {};
  @Input() URL: any;
  @Input() HideOutside: any;
  @Input() PLACEHOLDER: any;
  @Input() DATA: any = [];
  @Output("inputValue") inputValue: EventEmitter<any> = new EventEmitter();
  constructor(private localStorage: LocalStorageService, private api: MasterService, private templateService: TemplateService) {


  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.HideOutside) { this.showPopup = false }
  }

  ngOnInit() {
    this.resultArray = this.DATA;
  }
  removeTag(item: any) {
    this.resultArray = this.resultArray.filter((value: any) => value != item);
    this.inputValue.emit(this.resultArray)
    var wordsArray = item.split(' ');
    var newArray = wordsArray.slice(1);
    var resultString = newArray.join(' ');
    this.checked[resultString] = "uncheck";

    const idd = document.getElementById(resultString.trim());
    if (idd && idd instanceof HTMLInputElement) {
      idd.checked = false;
    }
  }

  openTemplate() {
    this.showPopup = true;
    this.getTemplate()
  }
  closeTemplate() {
    this.showPopup = false;
  }
  getTemplate() {
    this.loadingData = true;
    let param = {
      where: {
        specialty: this.localStorage.getSpecialty()
      }
    }
    this.templateService.getDynamicTemplateService(param, this.URL).subscribe({
      next: (res) => {
        if (res) {
          this.TemplateList = res;
          this.displayList = res.data;
          this.tempdisplayList = res;
          this.loadingData = false;
          console.log("this result : ", this.resultArray);
          this.resultArray.forEach((item: any) => {
            var wordsArray = item.trim().split(' ');
            var newArray = wordsArray.slice(1);
            var resultString = newArray.join(' ');
            this.checked[resultString.trim()] = wordsArray[0].trim();
          })
          setTimeout(() => {
            Object.keys(this.checked).map(key => {
              if (this.checked[key] === "uncheck") {
                console.log('true')
              }
              if (this.checked[key] !== "uncheck") {
                const idd = document.getElementById(key);
                if (idd && idd instanceof HTMLInputElement) {
                  idd.checked = true;
                }
              }
            });
          }, 100)
        }
      },
      error: (e) => {

      }
    })
  }
  seachData: any = [];
  searchRecord(event: any) {
    if(event) {
      this.displayList = this.TemplateList.data.filter((item: any) => item.toLowerCase().includes(event.toLowerCase()));
      this.resultArray.forEach((item: any) => {
        var wordsArray = item.trim().split(' ');
        var newArray = wordsArray.slice(1);
        var resultString = newArray.join(' ');
        this.checked[resultString.trim()] = wordsArray[0].trim();
      })
      setTimeout(() => {
        Object.keys(this.checked).map(key => {
          if (this.checked[key] === "uncheck") {
            console.log('true')
          }
          if (this.checked[key] !== "uncheck") {
            const idd = document.getElementById(key);
            if (idd && idd instanceof HTMLInputElement) {
              idd.checked = true;
            }
          }
        });
      }, 100)
      console.log("fatt : ",this.TemplateList.data);
    }
  }
  onKeyUp(event: any) {
    const inputValue: string = event.target.value;
    this.searchRecord(inputValue)
    if (event.code === 'Backspace' && !inputValue) {
      console.log("taarg : ",this.TemplateList);
      this.displayList = this.tempdisplayList.data;
      this.resultArray.forEach((item: any) => {
        var wordsArray = item.trim().split(' ');
        var newArray = wordsArray.slice(1);
        var resultString = newArray.join(' ');
        this.checked[resultString.trim()] = wordsArray[0].trim();
      })
      setTimeout(() => {
        Object.keys(this.checked).map(key => {
          if (this.checked[key] === "uncheck") {
            console.log('true')
          }
          if (this.checked[key] !== "uncheck") {
            const idd = document.getElementById(key);
            if (idd && idd instanceof HTMLInputElement) {
              idd.checked = true;
            }
          }
        });
      }, 100)
      // this.getTemplate();
      // this.removeTag(event.target.value);
      return;
    } else {
      if (event.code === 'Comma' || event.code === 'Enter') {
        this.displayList = this.tempdisplayList.data;
        if(event.target.value) {
          this.displayList.unshift(event.target.value);
        }
        this.addDiagnosis(event, inputValue, "", 0);
        event.target.value = ""
      }
    }
  }
  addDiagnosis(event: any, item: any, organ: any, index: any) {
    if (event.target.checked) {
      let existingArray = []
      let value: any = organ + " " + item
      existingArray = this.resultArray || [];
      this.resultArray = existingArray.concat(value);
      this.inputValue.emit(this.resultArray)
    } else {
      this.resultArray = this.resultArray.filter((it: any) => {
        var wordsArray = it.split(' ');
        var newArray = wordsArray.slice(1);
        var resultString = newArray.join(' ');
        return resultString.trim() != item.trim();
      });
      this.checked[item.trim()] = "uncheck";

    }
  }
  selectOrganFn(event: any, item: any) {
    this.checked[item.trim()] = event.target.value;
    if (this.checked[item.trim()] !== "") {
      this.checked[item.trim()] = event.target.value;
    } else {
      this.checked[item.trim()] = "uncheck";
    }
  }


}
