import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LeadService } from '../../services/lead.service';
import { PatientService } from '../../services/patient.service';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { FormControl, NgForm } from '@angular/forms';
import { ModalDismissReasons, NgbDatepickerModule, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { Toast, ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { CampaignService } from '../../services/campaign.service';
import { ToolbarComponent } from '../../shared/component/toolbar/toolbar.component';
@Component({
  selector: 'app-lead',
  templateUrl: './lead.component.html',
  styleUrls: ['./lead.component.scss']
})
export class LeadComponent {
  @ViewChild(ToolbarComponent) toolbar!: ToolbarComponent;
  private addPatientModalRef: NgbModalRef | null = null;
  topBarTitle: string = "Leads";
  isLoading = false;
  totalRows = 0;
  pageSize = 25;
  currentPage = 0;
  btnLoading: boolean = false;
  pageSizeOptions: number[] = [25, 50, 100];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  dateQuery = new FormControl();
  currentTab = 'tina';
  formAction = "add";
  closeResult = '';
  isSubmitted: boolean = false;
  displayedColumns: string[] = ["fname", 'lname', "email", "mobile", "date", "followup", "status", "action"];
  exportColumns: string[] = ["fname", "lname", "gender", "email", "mobile", "date", "followup", "status", "platform"];
  dateObject: any = { startDate: "", endDate: "" }
  lead: Lead = new Lead();
  leadFolloup: LeadFollowup = new LeadFollowup();
  @ViewChild("leadForm")
  leadForm!: NgForm;
  @ViewChild("leadFolloupForm")
  leadFolloupForm!: NgForm;
  leadsFollowList: any = [];
  leadChannels: any = [];
  exportArrayData = [];
  LeadArrayData = [];
  temp: any = [];
  subsciberListName: any = "";
  createCampaignFlag: boolean = false;
  patientFeature: boolean = false;
  role: any;
  permissions: any = [];
  modules: any = [];

  constructor(private toast: ToastrService, private api: LeadService, private api2: PatientService, private campaignService: CampaignService, private modalService: NgbModal, private localStorage: LocalStorageService) {

    //console.log(this.localStorage.getClientId())
    this.leadChannels = JSON.parse(JSON.stringify(this.localStorage.getLeadChannels()));

    if (this.localStorage.checkFeatures('campaign') == true) {
      this.createCampaignFlag = true;
      this.displayedColumns = ["select", "fname", 'lname', "email", "mobile", "date", "followup", "status", "action"]
    }
    //console.log(this.leadChannels)
  }
  ngAfterViewInit() {
    this.role = this.localStorage.getRole();
    this.permissions = JSON.parse(this.localStorage.getPermissions());
    this.modules = JSON.parse(JSON.stringify(this.localStorage.getFeatureModules()));
    if (this.getFeatureModuleAccess('patients') && this.getAccess('patients')) {
      this.patientFeature = true;
      console.log("patient Feature : ", this.patientFeature);
    }
    this.loadData();
    console.log(this.toolbar);

  }
  ngOnInit(): void { }

  searchFilter(query: any) {
    this.searchQuery.setValue(query);
  }
  ResetDateFilter(event: any) {
    this.dateQuery.setValue("");
  }
  ResetFilter(event: any) {
    this.searchQuery.setValue("");
  }
  clearleadForm() {
    this.lead = new Lead();
  }

  getFeatureModuleAccess(module_name: any) {
    return (this.modules.includes(module_name) ? true : false);
  }

  getAccess(module_name: any) {
    console.log("permissions : ", this.permissions)
    let result = this.permissions.filter((item: any) => {
      return item.module_name == module_name && item.active == true;
    })
    console.log("result : ", result[0])
    return (result[0]) ? true : false;
    //return true;
  }


  // load Initial table data from based on source type
  loadData() {
    try {
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      merge(this.dateQuery.valueChanges, this.searchQuery.valueChanges, this.sort.sortChange, this.paginator.page).pipe(startWith({}),
        switchMap(() => {
          console.log(this.dateQuery.value)
          let param = {
            where: { leadChannel: this.currentTab, hospital_id: this.localStorage.getHospitalId() },
            filter: this.searchQuery.value,
            page: (this.paginator.pageIndex * this.paginator.pageSize),
            limit: this.paginator.pageSize,
            // startDate:this.dateQuery.value.startDate,
            // endDate:this.dateQuery.value.endDate,
            order: { col: 'createdAt', dir: "desc" },
          }
          if (this.dateQuery.value) { param = { ...param, ...this.dateQuery.value } }

          return this.api.getAllData(param)
            .pipe(catchError(() => observableOf(null)));
        }), map((response: any) => {
          if (response === null) {
            return [];
          }

          this.totalRows = response.count;
          return response
        })
      ).subscribe({
        next: (data) => {
          // console.log(data)
          this.exportArrayData = data.rows;
          this.LeadArrayData = data.rows;
          this.dataSource = new MatTableDataSource<any>(data.rows);;
        },
        error: (e) => {
          console.error(e)
        },
        complete: () => {

        }
      })
    }
    catch (e) {
      console.error(e)
    }
  }
  dateFilter(event: any) {
    this.dateQuery.setValue(event);
  }
  followUps(content: any, element: any) {
    this.modalService.open(content, { size: 'xl', scrollable: true, });
    this.getLead(element.lead_id)
  }
  changeTab(tab: any) {
    this.currentTab = tab;
    this.loadData();
  }
  edit(content: any, object: any) {

    this.formAction = "update";
    this.modalService.open(content, { size: 'xl', scrollable: true });
    this.getLead(object.lead_id)

  }

  getLead(id: any) {
    let param = {
      lead_id: id,
      hospital_id: this.localStorage.getHospitalId()
    }
    this.api.getLead(param).subscribe({
      next: (res) => {
        //console.log(res)
        this.lead = res || {};
        this.leadsFollowList = res.leadsfollowups || [];
      },
      error(err) {

      },
    })
  }
  open(content: any) {
    this.formAction = "add";
    this.clearleadForm();
    this.modalService.open(content, { size: 'xl', scrollable: true, centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;

      },
      (reason) => {

        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  //save Lead Data
  SaveLead(isValid: any) {
    this.isSubmitted = true;

    if (isValid) {
      if (this.formAction == 'add') {
        this.btnLoading = true;
        this.lead.client_id = this.localStorage.getClientId();
        this.lead.hospital_id = this.localStorage.getHospitalId();
        this.lead.leadChannel = "manual";


        this.api.addLeadData(this.lead).subscribe({
          next: (c) => {
            this.modalService.dismissAll()
            this.lead = new Lead();
            this.loadData()
            this.btnLoading = false;
            this.toast.success("Lead Added Successfully", "Success!!")
          },
          error: (e) => {
            this.btnLoading = false;
          },
          complete: () => { }
        })
      }
      else if (this.formAction == 'update') {

        this.btnLoading = true;
        this.api.updateLead(this.lead).subscribe({
          next: (c) => {
            this.modalService.dismissAll()
            this.loadData()
            this.isSubmitted = false
            this.btnLoading = false;
            this.toast.success("Lead Updated Successfully", "Success!!")
            this.lead = new Lead();
          },
          error: (e) => {
            this.btnLoading = false;
            this.isSubmitted = false
          },
          complete: () => { }
        })
      }
      // console.log(this.lead)

    }

  }

  SaveLeadFollowUp(isValid: any) {
    this.isSubmitted = true
    if (isValid) {
      this.btnLoading = true;
      this.leadFolloup.lead_id = this.lead.lead_id;
      this.lead.hospital_id = this.localStorage.getHospitalId();
      this.api.addLeadFollowUp(this.leadFolloup).subscribe({
        next: (c) => {
          this.getLead(this.lead.lead_id)
          this.loadData();
          this.modalService.dismissAll();
          this.btnLoading = false;
          this.isSubmitted = false
          this.toast.success("Lead Followup Added Successfully", "Success!!")
        },
        error: (e) => {
          this.btnLoading = false;
          this.isSubmitted = false
        },
        complete: () => { }
      })
    }

  }
  export(header: any) {

    let excelRowData: any = [];
    this.exportArrayData.forEach((element: any) => {
      excelRowData.push({ 0: element.fname, 1: element.lname, 2: element.gender, 3: element.email, 4: element.mobile, 5: element.date_of_lead, 6: element.followup, 7: element.leadStattus, 8: element.leadChannel })
    });

    let excelData = [];
    let excelHeader = [];
    for (let i = 0; i < excelRowData.length; i++) {
      let element = excelRowData[i];
      let obj: any = {}
      for (let index = 0; index < header.length; index++) {
        let key = header[index]['key'];
        obj[key] = element[key]
      }
      excelData.push(obj)
    }
    for (let index = 0; index < header.length; index++) {
      let value = header[index]['value'];
      excelHeader.push(value)
    }
    // console.log(excelData)
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, [excelHeader]);
    XLSX.utils.sheet_add_json(ws, excelData, { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    XLSX.writeFile(wb, "Leads_data_" + day + "_" + month + "_" + year + "_" + date.getTime() + ".csv");


  }

  addSubscriberList(isValid: any) {
    this.isSubmitted = true
    if (isValid) {
      this.btnLoading = true;
      let list_number: any = [];
      let list_email: any = [];
      console.log(this.temp)
      const list = this.temp.reduce((res: any, item: any) => {
        if (item.mobile) {
          list_number.push(item.mobile);
        }
        if (item.email) {
          list_email.push(item.email);
        }
        res.push({ lead_id: item.lead_id, hospital_id: item.hospital_id, fname: item.fname, lname: item.lname, name: item.name, mobile: item.mobile, email: item.email });
        return res;
      }, []);
      let params = {
        "subscriber_name": this.subsciberListName,
        "hospital_id": this.localStorage.getHospitalId(),
        "total_subscriber": this.temp.length,
        "subscriber_list": list,
        "subscriber_phones": list_number,
        "subscriber_emails": list_email
      };
      //console.log(params)

      this.campaignService.createSubscriberList(params).subscribe({
        next: (c) => {
          this.modalService.dismissAll();
          this.temp = [];
          this.btnLoading = false;
          this.isSubmitted = false;
          this.toast.success("Subscriber List Added Successfully", "Success!!");
        },
        error: (e) => {
          this.btnLoading = false;
          this.isSubmitted = false
        },
        complete: () => { }
      })
    }
  }

  isAllSelected() {
    return this.dataSource.data.every((item) => item.isSelected);
  }

  isAnySelected() {
    return this.dataSource.data.some((item) => item.isSelected);
  }

  selectOne(event: any, element: any) {
    if (event.target.checked) {
      this.temp.push(this.LeadArrayData.find((lead: any) => lead.lead_id == element.lead_id));
    }
    else {
      this.temp = this.temp.filter(function (obj: any) {
        return obj.lead_id !== element.lead_id;
      })
    }

    if (this.temp.length > 0) {
      this.toolbar.isGroupActive = 'New Group';
    }
    if (this.temp.length <= 0) {
      this.toolbar.isGroupActive = '';
    }
  }

  selectAll(event: any) {

    var checkboxes: any = document.getElementsByClassName('check_input_manual');
    if (event.target.checked) {

      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == 'checkbox') {
          //console.log(this.LeadArrayData)
          let leadObject = this.LeadArrayData.find((lead: any) => lead.lead_id == checkboxes[i].value);
          // console.log(leadObject)
          checkboxes[i].checked = true;
          this.temp.push(leadObject)
        }
      }
    }
    else {
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == 'checkbox') {
          checkboxes[i].checked = false;
        }
      }
      this.temp = [];
    }

    console.log(this.temp)

    if (this.temp.length > 0) {
      this.toolbar.isGroupActive = 'New Group';
    }
    if (this.temp.length <= 0) {
      this.toolbar.isGroupActive = '';
    }
  }

  openList(content: any) {
    console.log("hello garuav")
    if (this.temp.length < 1) {
      this.toast.error("Please select a record to create the list !");
    }
    else {
      this.formAction = "add";
      this.modalService.open(content, { size: 'md', scrollable: true }).result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;

        },
        (reason) => {

          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        },
      );
    }
  }

  // Patients
  pateintBtn = false;
  selectedLeads: any = null;
  isPatientExist = false;

  addAsPatient(content: any, object: any) {
    this.selectedLeads = object;
    this.isPatientExist = object?.isPatient || false;
    if (this.isPatientExist) {
      this.toast.success("Existing Patient !");
    }
    else {
      this.addPatientModalRef = this.modalService.open(content, { windowClass: 'modal', scrollable: false, ariaLabelledBy: 'modal-basic-title', centered: true });
    }
  }

  addPatient() {
    this.pateintBtn = true;
    let newPatient = {
      "pId": "N/A",
      "hospital_id": this.localStorage.getHospitalId(),
      "center_id": this.localStorage.getCenterId(),
      "prefix": this.selectedLeads.prefix,
      "fname": this.selectedLeads.fname,
      "lname": this.selectedLeads.lname,
      "name": this.selectedLeads.fname + " " + this.selectedLeads.lname,
      "mobile": this.selectedLeads.mobile,
      "email": this.selectedLeads.email,
      "source": this.selectedLeads.leadChannel,
      "photo": null,
      "gender": null,
      "age": null,
      "dob": null,
      "blood_group": null,
      "uid_number": null,
      "abha_number": null,
      "alt_contact_number": null,
      "old_patient_record_number": null,
      "insurance_details": null,
      "address": null
    }
    try {
      this.api2.createPatient(newPatient).subscribe({
        next: (res: any) => {
          if (res) {
            console.log("resss : ", res)
            let param = {
              "lead_id": this.selectedLeads.lead_id,
              "isPatient": true
            }
            this.selectedLeads.isPatient = true;
            const data = this.dataSource.data;
            const rowToUpdate = data.find((item) => item.lead_id === this.selectedLeads.lead_id);
            if (rowToUpdate) {
              Object.assign(rowToUpdate, this.selectedLeads); // Update only the changed fields
            }

            // Refresh the table (optional if Angular detects changes automatically)
            this.dataSource.data = data;

            this.api.updateLead(param).subscribe({
              next: (res: any) => {
                console.log("lead res : ", res)
              }
            });
            this.pateintBtn = false;
            this.addPatientModalRef?.close();
            this.toast.success(res);
          }
        },
        error: (e) => {
          this.pateintBtn = false;
          this.addPatientModalRef?.close();
          this.toast.error("Failed !");
        }
      })
    } catch (error) {
      this.pateintBtn = false;
      this.addPatientModalRef?.close();
      this.toast.error("Failed !");
    }
  }

}
function observableOf(arg0: null): any {
  console.log(arg0)
  //throw new Error('Function not implemented.');
}

export class Lead {
  lead_id!: Number;
  fname: string = "";
  lname: string = "";
  leadsfollowup!: JSON;
  prefix: string = "";
  date_of_lead: String = moment().format("YYYY-MM-DD");
  time_of_lead: String = moment().format("HH:mm");
  gender: string = "";
  age: Number = 0;
  email: string = "";
  mobile: string = "";
  state: string = "";
  country: string = "";
  city: string = "";
  address: string = "";
  query: string = "";
  leadStattus: string = ""
  client_id: string = ""
  hospital_id: Number = 0
  leadChannel: String = ""
}
export class LeadFollowup {
  lead_id!: Number;
  followup_status: string = "";
  feedback: string = "";
  user_query: string = "";
  comment: string = "";
}

