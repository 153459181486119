import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AppointmentService } from 'src/app/private/services/appointment.service';
import { UploadService } from 'src/app/private/services/upload.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss']
})
export class PatientComponent {
  @Input() titleOfHead: any = {
    title: '',
    root: 'Patient'
  };
  @Input() patient:any;
  imgUpload:boolean = false;
  btnUpdate:boolean = false;
  imgTitle:any = "Upload Image"
  file:any ;
  url:any ='assets/img/no-image.jpg';
  editMode:boolean = false;
  constructor(private uploadService:UploadService, private toast:ToastrService, private api:AppointmentService , private localstorage:LocalStorageService, private route:ActivatedRoute) {

  }
  calculateAge() {
    
    if (this.patient.dob) {
 
      const today = new Date();
      const birthDate = new Date(this.patient.dob);
      let age:any = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
  
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
  
      this.patient.age = age;
    }
  }

  calculateDOB() {
    
    if (this.patient.age !== null) {
    
      let age:any = this.patient.age;
      const today = new Date();
      const birthYear = today.getFullYear() - age;
      const birthMonth = today.getMonth();
      const birthDay = today.getDate();
  
      this.patient.dob = new Date(birthYear, birthMonth, birthDay).toISOString().split('T')[0]
     
    }
  }
  ngOnInit(): void {
    // checking idf router params contains apointments or not
    this.route.data.subscribe(data => {
      console.log(data)
       if(data['dashabord']== 'opd'){
        this.titleOfHead.title = "OPD"
       }
       else if(data['dashabord']== 'IPD'){
        this.titleOfHead.title = "IPD"
       } 
       else{
        this.titleOfHead.title = "Appointments"
       }
    });
  }


  ngAfterViewInit() {
   this.url = this.patient.photo;
  }
  onFileSelected(event:any)
  {

    const files = event.target.files;
    if (files.length === 0)
        return;

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
        this.toast.error('Only images are supported.');
        return;
    }

    const reader = new FileReader();
    this.file = files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {

        this.patient.photo = reader.result;
      //  this.updateData();
    }

  }

  uploadImage()
  {

    if (this.file) {
      console.log(  this.file)
       this.imgUpload = true;
       this.imgTitle = "Uploading.."
        let name =   this.file.name;
        const formData = new FormData();
        formData.append("file", this.file, this.patient.pId);
        let folder = "public/hospital-"+this.localstorage.getHospitalId()+"/"+this.patient.pId+"/profile"
        formData.append("folders", folder);
        formData.append("bucket", "emruploads");
        console.log(formData)
        this.uploadService.uploadPatientProfile(formData).subscribe({
          next:(res)=>{
           console.log(res)
           if(res)
           {


            let param = {
              id:this.patient.id,
              hospital_id:this.patient.hospital_id,
              center_id:this.patient.center_id,
              photo:res.url

            }
            this.patient.photo = res.url;
            this.file = "";
            this.api.updatePatient(param).subscribe({
              next:(res:any)=>{
                if(res)
                {
                  this.imgUpload = false;
               
                  this.toast.success("Profile Uploaded Successfully")
                }
              }
            })
            this.imgTitle = "Upload Image"
           }
          },
          error:(e)=>{
            this.imgTitle = "Upload Image"
          }
        })
        //const upload$ = this.http.post("/api/thumbnail-upload", formData);
       // upload$.subscribe();

    }
  }
  updateData()
  {
    this.btnUpdate = true
    this.api.updatePatient(this.patient).subscribe({
      next:(res:any)=>{
        if(res)
        {
          this.btnUpdate = false;
          this.toast.success("Profile Uploaded Successfully")
        }
      },
      error:(e)=>{
        this.btnUpdate = false
      }
    })
  }

  changeEditMode() {
    this.editMode = !this.editMode;
  }
}



