import { filter } from 'rxjs';
import { Component, ViewChild, Input } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { PatientService } from '../../services/patient.service';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { merge, startWith, switchMap, catchError, map } from 'rxjs';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { UploadService } from 'src/app/private/services/upload.service';
import { AppointmentService } from 'src/app/private/services/appointment.service';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss']
})
export class PatientsComponent {
  private addModalRef: NgbModalRef | null = null;
  @Input() patient: any;
  newPatient: any = {
    "pId": "N/A",
    "hospital_id": null,
    "center_id": null,
    "prefix": null,
    "photo": null,
    "fname": null,
    "lname": null,
    "name": null,
    "gender": null,
    "age": null,
    "months": null,
    "dob": null,
    "blood_group": null,
    "uid_number": null,
    "abha_number": null,
    "alt_contact_number": null,
    "old_patient_record_number": null,
    "insurance_details": null,
    "mobile": null,
    "email": null,
    "address": null,
    "source": "webapp",
  }
  imgUpload: boolean = false;
  btnUpdate: boolean = false;
  imgTitle: any = "Upload Image"
  file: any;
  url: any = 'assets/img/no-image.jpg';
  editMode: boolean = false;
  isLoading = false;
  btnLoading = false;
  topBarTitle: string = "Patients";
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 100];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  appointment: Appointment = new Appointment();
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  isSubmittedresCheduled: boolean = false;
  dateQuery = new FormControl();
  formAction = "add";
  closeResult = '';
  DateList: any = [];
  TimeList: any = [];
  current_id_hover: any;
  paymentDetail: any = {
    payment_status: "",
    remarks: "",
    service: "",
    pincode: "",
  }
  @ViewChild("reappointmentForm")
  reappointmentForm!: NgForm;
  isSubmitted: boolean = false;
  exportArrayData: any = [];
  isOnlinePayment: boolean = false;
  displayedColumns: string[] = ["pId", "name", "gender", "age", "dob", "blood_group", "mobile", "email", "action"];
  exportColumns: any = [];
  topPosition: any;
  leftPosition: any;
  hoveredRow: any;
  departmentListDp: any = []
  DoctorDpList: any = []
  selectedItem: any;
  filtersObject: any = {};
  timeZone: any = Intl.DateTimeFormat().resolvedOptions().timeZone || "Asia/Calcutta";
  app_count: any = {
    all: null,
    today: null,
    tomorrow: null,
    week: null,
    month: null
  }
  selected_tab: any = "all"
  constructor(private uploadService: UploadService, private router: Router, private toast: ToastrService, private api: PatientService, private modalService: NgbModal, private localStorage: LocalStorageService) {

  }
  ngAfterViewInit() {
    this.loadData();
  }

  formatTo12Hour(time: string): string {
    if (this.localStorage.isTwelveHourFormat()) {
      return moment(time, 'HH:mm').format('hh:mm A');
    }
    else {
      return time;
    }
  }

  showInfo(row: any, event: any) {
    this.hoveredRow = row;
    this.topPosition = event.clientY;
    this.leftPosition = event.clientX;
    this.current_id_hover = row.appointment.app_id
  }
  onMouseMove(row: any, event: MouseEvent): void {
    this.current_id_hover = row.appointment.app_id;
  }
  openDialog(element: any) {

  }
  hideInfo() {
    this.current_id_hover = "";
  }

  // load Initial table data from based on source type
  loadData() {
    try {
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      merge(this.dateQuery.valueChanges, this.searchQuery.valueChanges, this.sort.sortChange, this.paginator.page).pipe(startWith({}),
        switchMap(() => {
          console.log("search val : ", this.searchQuery.value)
          let param = {
            where: { hospital_id: this.localStorage.getHospitalId(), center_id: this.localStorage.getCenterId() },
            filter: this.searchQuery.value,
            custom_filter:this.filtersObject,
            page: (this.paginator.pageIndex * this.paginator.pageSize),
            limit: this.paginator.pageSize,
            order: [["id", "DESC"]]
          }
          if (this.dateQuery.value) { param = { ...param, ...this.dateQuery.value } }
          if (this.localStorage.checkFullAccessForTableList(this.localStorage.getRole())) {
            param = param
          } else {
            param = { ...param, ...{ user_id: this.localStorage.getUserId() } }
          }
          //console.log(this.localStorage.getRole())
          return this.api.getAllPatientWithCount(param)
            .pipe(catchError(() => observableOf(null)));
        }), map((response: any) => {
          if (response === null) {
            return [];
          }
          if (this.selected_tab == 'all') {

            this.app_count = {
              all: response.count,
              today: null,
              tomorrow: null,
              week: null,
              month: null
            }
          }
          this.totalRows = response.count;
          return response
        })
      ).subscribe({
        next: (data) => {
          // console.log(data)
          this.exportArrayData = data.rows;
          this.dataSource = new MatTableDataSource<any>(data.rows);
          this.exportColumns = ["PId", "Prefix", "Name", "Gender", "Age", "DOB", "Mobile", "Email", "Address", "Blood Group"];
        },
        error: (e) => {
          console.error(e)
        },
        complete: () => {

        }
      })
    }
    catch (e) {
      console.error(e)
    }
  }
  searchFilter(query: any) {

    // alert(query)
    this.searchQuery.setValue(query);
  }

  loadAppointmentByTab(type: any) {
    this.selected_tab = 'all'
  }

  open(content: any) {
    this.addModalRef = this.modalService.open(content, { size: 'lg', scrollable: false });

  }
  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  viewPatientfn(content: any, item: any) {
    // this.selectedItem = item;
    this.patient = item
    this.modalService.open(content, { windowClass: 'modal modal-lg', scrollable: false, ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
      if (result === 'yes') {

      }
    }, (reason) => {

    });
  }

  toNormalCapitalization(text: any) {
    return text.toLowerCase().replace(/(?:^|\s)\S/g, function (a: any) { return a.toUpperCase(); });
  }

  onFileChange(args: any) {
    const target: DataTransfer = <DataTransfer>(args.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      //console.log(data); // Data will be logged in array format containing objects
    };
  }
  export(header: any) {
    //console.log(header)
    console.log("exportArrayData : ", this.exportArrayData);
    let excelRowData: any = [];
    this.exportArrayData.forEach((element: any) => {
      excelRowData.push({ 0: element.pId, 1: element.prefix, 2: element.fname + " " + element.lname, 3: element.gender, 4: element.age, 5: element.dob, 6: element.mobile, 7: element.email, 8: element.address, 9: element.blood_group })
    });

    let excelData = [];
    let excelHeader = [];
    for (let i = 0; i < excelRowData.length; i++) {
      let element = excelRowData[i];
      let obj: any = {}
      for (let index = 0; index < header.length; index++) {
        let key = header[index]['key'];
        obj[key] = element[key]
      }
      excelData.push(obj)
    }
    for (let index = 0; index < header.length; index++) {
      let value = header[index]['value'];
      excelHeader.push(value)
    }
    //console.log(excelData)
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, [excelHeader]);
    XLSX.utils.sheet_add_json(ws, excelData, { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    XLSX.writeFile(wb, "Patients_data_" + day + "_" + month + "_" + year + "_" + date.getTime() + ".csv");
  }

  apptnotes: any = {};
  remarkNote = "";
  reasonNote = "";
  selectedRow: any = null;

  clearNote() {
    this.apptnotes = [];
    this.remarkNote = "";
    this.reasonNote = "";
  }

  addPatient(content: any) {
    this.patient = []
    this.modalService.open(content, { windowClass: 'modal modal-huge', scrollable: false, ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
      console.log("result : ", result)
      // if (result === 'yes') {  
      //   console.log("result : ",result)
      //   // let param = {
      //   //   patient_id:item.appointment.patient_id,
      //   //   app_id:item.appointment.app_id,
      //   //   hospital_id:this.localStorage.getHospitalId(),
      //   //   status:"no-show",
      //   // }
      //   // this.api.updateAppointment(param).subscribe({
      //   //   next:(res)=>{
      //   //     this.modalService.dismissAll();
      //   //     this.toast.success("Appointment Updated Successfully")
      //   //     this.loadData()
      //   //   },

      //   //   error:(e)=>{

      // }
      //   })
      // }  
      // }, (reason) => {  

    });
  }

  testy() {
    if (this.remarkNote) {
      let now = new Date();
      let date = new Intl.DateTimeFormat('en-GB').format(now).replace(/\//g, '-'); // e.g., "11/27/2024"
      let time = now.toLocaleTimeString(); // e.g., "10:15:30 AM"
      let note = {
        "remark": this.remarkNote,
        "reason": this.reasonNote,
        "date": date,
        "time": time
      }
      this.selectedRow.appointment.apptRemarks.push(note);
      console.log()

      // const data = this.dataSource.data;

      // // Find and update the selected row directly
      // const rowToUpdate = data.find((item) => item.id === this.selectedRow.id);
      // if (rowToUpdate) {
      //   Object.assign(rowToUpdate, this.selectedRow); // Update only the changed fields
      // }

      // // Refresh the table (optional if Angular detects changes automatically)
      // this.dataSource.data = data;

      console.log("this.apptnotes : ", this.apptnotes);
      this.apptnotes.push(note)
      console.log("new note : ", note);
    }
  }
  actionButton = "search"
  SearchInput = "";

  customSearchFilter(event: any) {
    this.filtersObject = event;
    this.loadData();
  }
  dateFilter(event: any) {
    //alert(event)
    this.dateQuery.setValue(event)
  }

  showFilterData(event: any) {
    if (event) {

    }
  }

  calculateAge() {

    if (this.patient.dob) {

      const today = new Date();
      const birthDate = new Date(this.patient.dob);
      let age: any = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      console.log("months : ",monthDiff);
      this.patient.months = String(monthDiff);
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      this.patient.age = age;
    }
  }

  calculateDOB() {

    if (this.patient.age !== null) {

      let age: any = this.patient.age;
      const today = new Date();
      const birthYear = today.getFullYear() - age;
      const birthMonth = today.getMonth();
      const birthDay = today.getDate();

      this.patient.dob = new Date(birthYear, birthMonth, birthDay).toISOString().split('T')[0]

    }
  }

  onFileSelected(event: any) {

    const files = event.target.files;
    if (files.length === 0)
      return;

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.toast.error('Only images are supported.');
      return;
    }

    const reader = new FileReader();
    this.file = files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {

      this.patient.photo = reader.result;
      //  this.updateData();
    }

  }

  uploadImage() {

    if (this.file) {
      console.log(this.file)
      this.imgUpload = true;
      this.imgTitle = "Uploading.."
      let name = this.file.name;
      const formData = new FormData();
      formData.append("file", this.file, this.patient.pId);
      let folder = "public/hospital-" + this.localStorage.getHospitalId() + "/" + this.patient.pId + "/profile"
      formData.append("folders", folder);
      formData.append("bucket", "emruploads");
      console.log(formData)
      this.uploadService.uploadPatientProfile(formData).subscribe({
        next: (res) => {
          console.log(res)
          if (res) {


            let param = {
              id: this.patient.id,
              hospital_id: this.patient.hospital_id,
              center_id: this.patient.center_id,
              photo: res.url

            }
            this.patient.photo = res.url;
            this.file = "";
            this.api.updatePatient(param).subscribe({
              next: (res: any) => {
                if (res) {
                  this.imgUpload = false;

                  this.toast.success("Profile Uploaded Successfully")
                }
              }
            })
            this.imgTitle = "Upload Image"
          }
        },
        error: (e) => {
          this.imgTitle = "Upload Image"
        }
      })
    }
  }

  updateData() {
    this.btnUpdate = true
    this.api.updatePatient(this.patient).subscribe({
      next: (res: any) => {
        if (res) {
          this.btnUpdate = false;
          this.toast.success("Profile Updated Successfully")
        }
      },
      error: (e) => {
        this.btnUpdate = false
      }
    })
  }

  changeEditMode() {
    this.editMode = !this.editMode;
  }

  addData() {
    this.btnUpdate = true
    this.newPatient.name = this.newPatient.fname+" "+this.newPatient.lname;
    this.newPatient.hospital_id = this.localStorage.getHospitalId();
    this.newPatient.center_id = this.localStorage.getCenterId();
    this.api.createPatient(this.newPatient).subscribe({
      next: (res: any) => {
        if (res) {
          this.btnUpdate = false;
          Object.keys(this.newPatient).forEach(key => this.newPatient [key] = "");
          this.addModalRef?.close();
          this.toast.success("Profile created Successfully");
        }
      },
      error: (e) => {
        this.btnUpdate = false
        this.toast.error("Profile creation Failed")
      }
    })
  }

}

function observableOf(arg0: null): any {
  //throw new Error('Function not :mplemented.');
}



export class Appointment {
  app_id!: Number;
  user!: any;
  appointment_source: string = "webapp";
  appointment_type: string = "";
  appointment_date: string = "";
  appointment_time: string = "";
  status: string = "booked";
  user_id: string = "";
  patient_id: Number = 0;
  dept_id: string = "";
  client_id: string = ""
  check_in_type: string = "follow-up";
  opd_number: string = ""
  hospital_id: Number = 0
  center_id: Number = 0
}