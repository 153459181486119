import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(private http: HttpClient) { }

  getAllPatient(data:any): Observable<any> {
    return this.http.post(environment.getAllPatients,data);
  }

  getAllPatientWithCount(data:any): Observable<any> {
    return this.http.post(environment.getAllPatientWithCount,data);
  }

  createPatient(data:any): Observable<any> {
    return this.http.post(environment.createPatient,data);
  }
  getPatient(data:any): Observable<any> {
    return this.http.post(environment.getPatient,data);
  }
  updatePatient(data:any): Observable<any> {
    return this.http.post(environment.updatePatient,data);
  }
  getPatientBySearch(data:any): Observable<any> {
    return this.http.post(environment.getPatientBySearch,data);
  }
}
