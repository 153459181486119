import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IpdchartService } from 'src/app/private/services/ipdchart.service';
import { TemplateService } from 'src/app/private/services/template.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { UsersService } from 'src/app/private/services/users.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ipd-procedures',
  templateUrl: './ipd-procedures.component.html',
  styleUrls: ['./ipd-procedures.component.scss']
})
export class IpdProceduresComponent {
  @Input() titleOfHead: any = {
    title: 'IPD',
    root: 'Procedures'
  };
  @Input() patient:any;
btnLoading:boolean = false;
procedureList:any = []
resultArray:any = []
doctorList:any = [];
procedurePackageList:any = []
procedures:any = [];
anesthetistInchargeObject:any = {};
currentTab:any;
id:any;
ipd_app:any;
layoutLoading:boolean = false;
current_patient_id:any;
selectedSupply: any = [];
selectedMedicine: any = [];
consumable_name : any = "";
ipd_app_id:any;
eye_name:any = "";
prevSelectedSupply: any = [];
procedureObject:any = {
  procedure_date:"",
  procedure_time:"",
  ot_no:"",
  required_anesthesia: "",
  anesthesia_details :{
    anesthesia_type :"",
    anesthetist_name :"",
    start_time:"",
    end_time:"",
    duration:"",
  },
  procedure_data:[],
  procedure_remarks:"",
  consumable:[]
 }

 showPopup: boolean = false;
 loadingData: boolean = false;
 supplyTypeList: any = [
  "Medications",
  "Dressings and Bandages",
  "Disposable Gloves",
  "Disposable Masks",
  "Disposable Gowns and Aprons",
  "Disposable Caps and Shoe Covers",
  "Catheters and Tubings",
  "IV Fluids",
  "Surgical Supplies",
  "Diagnostic Kits",
  "Oxygen Supplies",
  "Nutritional Supplements",
  "Sanitizers and Disinfectants",
  "Sterile Water",
  "Incontinence Products",
  "Ophthalmic Medications",
  "Eye Patches",
  "Ophthalmic Instruments",
  "Eye Shields",
  "Artificial Tears",
  "Contact Lens Solutions",
  "Tonometry Supplies",
  "Eye Lubricants"
]
constructor(private user:UsersService,public toast:ToastrService ,public route:ActivatedRoute,private ipdChartData:IpdchartService, private templateService:TemplateService,private localStorage:LocalStorageService)
{
  this.route.params.subscribe(params =>
    {
      this.current_patient_id = params['id']
      this.ipd_app_id = params['ipd_app_id']
    })
}
onProcedureChange(event:any)
{
  let id = event.target.value;
   let procedure = this.procedureList.find((item:any) => item.id == id)
   let exist = this.procedures.find((item:any) => item.id == id)
   console.log(exist)
  if(!exist)
  {
    let pro_name = this.eye_name+" "+procedure.item;
    this.procedures.push({
      id:procedure.id,
      cat_id:procedure.cat_id,
      procedure_name:pro_name,
      price:procedure.price,
     })
  }

  // console.log(procedure)

}

checkInclude(val:any,arr:any)
{
  let exist = arr.find((item:any) => JSON.stringify(item) === JSON.stringify(val))
  if(exist)
  {
    return true;
  }
  return false;
}

onSelectionChange(){
  if(!this.procedureObject.consumable) this.procedureObject.consumable = [];
  const deselectedValues = this.prevSelectedSupply.filter((value : any) => !this.checkInclude(value,this.selectedMedicine));
  if(deselectedValues.length > 0){
    this.procedureObject.consumable = this.procedureObject.consumable.filter((item:any)=> !(this.checkInclude(item,deselectedValues)))
  }else{
    const selectedValues = this.selectedMedicine.filter((value : any) => !this.checkInclude(value,this.prevSelectedSupply));
    this.procedureObject.consumable.push(
      selectedValues[selectedValues.length - 1]
    )
  }
  this.prevSelectedSupply = this.selectedMedicine;
}

removeMedItem(val : any){
  this.procedureObject.consumable = this.procedureObject.consumable.filter((item:any)=> !(this.checkInclude(item,[val])));
    this.selectedMedicine = this.selectedMedicine.filter((item:any)=> !(this.checkInclude(item,[val])));
    this.prevSelectedSupply = this.selectedMedicine;
}


getSupplyList(val : any){
  this.selectedMedicine = [];
  this.prevSelectedSupply = [];
  this.loadingData = true;
  this.selectedSupply = []
  this.ipdChartData.getAllSupply({
    where : {
      category : val
    }
  }).subscribe({
    next:(res)=>{
      if(res)
      {
        this.loadingData = false;
        this.selectedSupply = res;
        this.showPopup = true;
        console.log(this.selectedSupply)
      }
    },
    error:(e)=>{
      this.loadingData = false;
    }
  })
}
SaveProcedureData()
{
  if(this.id)
  {
    this.procedureObject.procedure_data = this.procedures;
   this.btnLoading = true;
   let param = {
     hospital_id:this.localStorage.getHospitalId(),
     center_id:this.localStorage.getCenterId(),
     id:this.id,
     ipd_app_id:this.ipd_app_id,
     procedures:this.procedureObject,
     }

   console.log(param)
    this.ipdChartData.updateIpdChartData(param).subscribe({
     next:(res)=>{
       if(res)
       {
         this.btnLoading = false;
         this.toast.success("Data Saved successfully...")

       }
     },error:(e)=>{
       this.btnLoading = false;
     }
    })
  }
  else{
    this.btnLoading = true;
    this.procedureObject.procedure_data = this.procedures;
   let param = {
     hospital_id:this.localStorage.getHospitalId(),
     center_id:this.localStorage.getCenterId(),
     patient_id:this.current_patient_id,
     ipd_app_id:this.ipd_app_id,
     procedures:this.procedureObject,
     }

     console.dir(param)
    this.ipdChartData.createIpdChartData(param).subscribe({
     next:(res)=>{
       if(res)
       {
         this.btnLoading = false;
         this.toast.success("Data Saved successfully...")

       }
     },error:(e)=>{
       this.btnLoading = false;
     }
    })
  }
}
remove(row:any)
{
  this.procedures = this.procedures.filter((item:any) => item.id != row.id)
}
ngOnInit()
{
  this.getTemplate();
}
ngAfterViewInit()
{
  this.getPatientProcedureData();
  this.loadDoctors();
}

loadDoctors()
  {
    let param = {
       hospital_id:this.localStorage.getHospitalId(),
       center_id:this.localStorage.getCenterId(),
       roles:['Anesthetist']
    }
    this.user.getDoctors(param).subscribe({
      next:(res)=>{
         this.doctorList = res;
      },
      error:(e)=>{
        console.log(e)
      }
    })
  }

  onDoctorChange(event:any)
  {
   let id = event.target.value;
   if(id)
   {
    let selectedDoctor = this.doctorList.filter((item:any)=>item.user_id = id);
    this.anesthetistInchargeObject = selectedDoctor[0]
   }
  }
getPatientProcedureData()
{
  // console.log(this.app_data)

 let param = {
  where:{
 hospital_id:this.localStorage.getHospitalId(),
  center_id:this.localStorage.getCenterId(),
  patient_id:this.current_patient_id,
  },
  attributes:['id','ipd_app_id','hospital_id','patient_id','center_id','procedures']
}
this.ipdChartData.getAllIpdChartData(param).subscribe({
  next:(res)=>{
   if(res)
   {
    // this.count = res.filter((item:any)=>{return item.investigations}).length;
   //  this.investigationListData = res.filter((item:any)=>{return item.app_id != this.ipd_app.appointment.app_id && item.investigations });
     let existingObject  = res.find((item:any)=>{return item.ipd_app_id == this.ipd_app_id })

     //console.log(existingObject)
     if(existingObject!=undefined)
     {
       this.id =  existingObject.id || undefined;

       if(existingObject.procedures){
       this.currentTab = existingObject;
       this.procedureObject = existingObject.procedures;
       this.procedures =  existingObject.procedures.procedure_data || []
       }

       //alert(existingObject.procedures.anesthesia_details)
       this.procedureObject.anesthesia_details = (existingObject.procedures.anesthesia_details == undefined) ? {
        anesthesia_type :"",
        anesthetist_name :"",
        start_time:"",
        end_time:"",
        duration:"",
      } :  existingObject.procedures.anesthesia_details   
     }
     this.layoutLoading = true;

   }

  },
  error:(e)=>{

  }
})
}
getTemplate()
{

  let param = {

      hospital_id:this.localStorage.getHospitalId(),
      center_id:this.localStorage.getCenterId(),
      cat_id:2

  }
  this.templateService.getDynamicTemplateService(param,environment.getAllPriceMaster).subscribe({
    next:(res)=>{
      if(res)
      {
        this.procedureList = res;
        this.getPackages();
      }
    },
    error:(e)=>{

    }
  })
}
onProcedurePackageChange(event:any)
{
  let value = event.target.value;
  if(value)
  {
    let row  =  this.procedurePackageList.find((item:any)=> item.id == value)
    this.procedures = row.package;
  }
}
getPackages()
{

  let param = {
      hospital_id:this.localStorage.getHospitalId(),
      center_id:this.localStorage.getCenterId(),
  }
  this.templateService.getProcedurePackages(param).subscribe({
    next:(res)=>{
      if(res)
      {
        this.procedurePackageList = res;
      }
    },
    error:(e)=>{

    }
  })
}


}
